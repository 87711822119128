import React from "react";
import SignUpContainer from '../containers/SignUpContainer'

export default function SignupPage() {
  return (
    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">{/* Content goes here */}
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '70px', paddingBottom: '70px'}}>
        <div className="w-96">    
        <SignUpContainer />
        </div>
      </div>
    </div>
  );
}



   