// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navcircle {
    width: 30px; /* Adjust size as needed */
    height: 30px; /* Adjust size as needed */
    background-color: rgb(191, 218, 72);
    border-radius: 50%; /* Ensures the element is a circle */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px; /* Adjust font size as needed */
    color: white; /* Color of the letter */
  }

  .navcircleinitial {
    font-size: medium;
  }`, "",{"version":3,"sources":["webpack://./src/navStyles.css"],"names":[],"mappings":"AAAA;IACI,WAAW,EAAE,0BAA0B;IACvC,YAAY,EAAE,0BAA0B;IACxC,mCAAmC;IACnC,kBAAkB,EAAE,oCAAoC;IACxD,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe,EAAE,+BAA+B;IAChD,YAAY,EAAE,wBAAwB;EACxC;;EAEA;IACE,iBAAiB;EACnB","sourcesContent":[".navcircle {\r\n    width: 30px; /* Adjust size as needed */\r\n    height: 30px; /* Adjust size as needed */\r\n    background-color: rgb(191, 218, 72);\r\n    border-radius: 50%; /* Ensures the element is a circle */\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    font-size: 36px; /* Adjust font size as needed */\r\n    color: white; /* Color of the letter */\r\n  }\r\n\r\n  .navcircleinitial {\r\n    font-size: medium;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
