/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { format } from 'date-fns'

export default function TailwindSimpleTable({ data }) {
    // const formattedTableData = data.map(
    //     ({ id, snippet }) => ({
    //         // created: format(new Date(created), 'MM/dd/yyyy'),
    //         videoId: id.videoId,
    //         url: `https://www.youtube.com/watch?v=${id.videoId}`,
    //         onClickSelect: () => console.log('select vid'),
    //         title: snippet.title,
    //         created: snippet.publishedAt
          
    //         // onClick: () => onClick(id),
    //     }),
    // )

    // console.log('formattedTableData: ', formattedTableData)

    return (
        // <div className="px-4 sm:px-6 lg:px-8">
        <div>
            <div className='mt-8 flow-root'>
                <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                    <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
                        <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
                            <table className='min-w-full divide-y divide-gray-300'>
                                <thead className='bg-gray-50'>
                                    <tr>
                                        <th
                                            scope='col'
                                            className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                                        >
                                            Created
                                        </th>
                                        <th
                                            scope='col'
                                            className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                        >
                                            Title
                                        </th>
                                        <th
                                            scope='col'
                                            className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                        >
                                            Url
                                        </th>
                                        <th
                                            scope='col'
                                            className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                        />
                                        <th
                                            scope='col'
                                            className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                                        />

                                        {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                        <span className="sr-only">Edit</span>
                                    </th> */}
                                    </tr>
                                </thead>
                                <tbody className='divide-y divide-gray-200'>
                                    {data.map(
                                        ({
                                            id,
                                            url,
                                            created,
                                            title,
                                            // status,
                                            onClickSelect,
                                            // onClickDownload,
                                        }) => (
                                            <tr key={id}>
                                                <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0'>
                                                    {created
                                                        ? format(
                                                              new Date(created),
                                                              'dd/MM/yyyy - HH:mm:ss',
                                                          )
                                                        : ''}
                                                </td>
                                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                                    {title}
                                                </td>
                                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                                    {url}
                                                </td>
                                                {/* <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                                    {status}
                                                </td>
                                                <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0'>
                                                    {status === 'Complete' && (
                                                        <button
                                                            type='button'
                                                            className='text-indigo-600 hover:text-indigo-900'
                                                            onClick={() =>
                                                                onClickView(id)
                                                            }
                                                        >
                                                            View
                                                        </button>
                                                    )}
                                                </td> */}
                                                <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0'>
                                                    {/* {status === 'Complete' && (
                                                        <button
                                                            type='button'
                                                            className='text-indigo-600 hover:text-indigo-900'
                                                            onClick={() =>
                                                                onClickDownload(
                                                                    id,
                                                                )
                                                            }
                                                        >
                                                            Download
                                                        </button>
                                                    )} */}

                                                        <button
                                                            type='button'
                                                            className='text-indigo-600 hover:text-indigo-900'
                                                            onClick={() =>
                                                                onClickSelect()
                                                            }
                                                        >
                                                            Select
                                                        </button>

                                                </td>
                                            </tr>
                                        ),
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
