import React from 'react'

function NavSkeletonLoader() {
  return (
    <div className="bg-transparent p-4 flex items-center justify-between w-full ">
      {/* Navigation Links (left-aligned) */}
      <div className="flex items-center space-x-4 justify-start">
        {/* Skeleton Navigation Link */}
        <div className="h-4 w-20 bg-gray-300 rounded" />
        {/* Skeleton Navigation Link */}
        <div className="h-4 w-20 bg-gray-300 rounded" />
        {/* Skeleton Navigation Link */}
        <div className="h-4 w-20 bg-gray-300 rounded" />
      </div>

      {/* Logo (right-aligned) */}
      <div className="flex items-center space-x-4 justify-end pr-5">
       
        <div className="h-6 w-6 bg-gray-300 rounded-full" />
        <div className="h-6 w-6 bg-gray-300 rounded-full" />
        <div className="h-4 w-20 bg-gray-300 rounded" />
      </div>
    </div>
  );
}

export default NavSkeletonLoader