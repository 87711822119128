/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { PURGE } from "redux-persist"

const initialState = {
    isLoggedIn: false,
    authError: undefined,
}

export const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIsLoggedIn: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.isLoggedIn = action.payload
        },
        setAuthError: (state, action) => {
            const { type, message } = action.payload

            state.authError = { type, message }
        },
        clearAuthError: (state) => {
            state.authError = null
        },
    },
})

auth.extraReducers = (builder) => {
    builder.addCase(PURGE, () => initialState)
  }

// Action creators are generated for each case reducer function
export const { setIsLoggedIn, setAuthError, clearAuthError } = auth.actions

export const authReducer = auth.reducer
