import React, { useEffect } from 'react'
// import { useNavigate  } from "react-router-dom";
// import { signInWithPopup, signOut } from "firebase/auth";
import { useDispatch, useSelector } from 'react-redux'
// import { auth, googleProvider } from '../firebase'
// import { useNavigate } from 'react-router-dom'
import { useHandleGoogleAuth } from '../apiCalls/auth/googleAuthMethods'
import { useHandleAuth } from '../apiCalls/auth/authMethods'
import { globalDispatch, authDispatch } from '../store'

import AuthenticationForm from '../molecules/AuthenticationForm'

export default function LoginContainer() {
    const dispatch = useDispatch()
    //   const navigate = useNavigate ();
    const { googleLogIn } = useHandleGoogleAuth()
    const { standardLogin } = useHandleAuth()
    const authError = useSelector((state) => state.auth.authError)
    // const isSubscriptionActive = useSelector((state) => state.user?.subscription?.isActive)
    // const navigate = useNavigate()

    // function handleLoginGoogle() {
    //   //1 - init Google Auth Provider

    //   //2 - create the popup signIn
    //   signInWithPopup(auth, googleProvider).then(
    //     async (result) => {
    //       //3 - pick the result and store the token
    //       const token = await auth?.currentUser?.getIdToken(true);
    //       //4 - check if have token in the current user
    //       if (token) {
    //         //5 - put the token at localStorage (We'll use this to make requests)
    //         localStorage.setItem("@token", token);
    //         //6 - navigate user to the book list
    //         navigate("/book-list");
    //       }
    //     }).catch(err => console.log('error: ', err))
    // }

    useEffect(
        () => () => {
            if(authError)
                dispatch(authDispatch.clearAuthError())
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    )

    function handleNormalLogin({ email, password }) {
        // normal sign up

        dispatch(globalDispatch.setIsLoading(true))
        standardLogin({ email, password }).then(() => {
            dispatch(globalDispatch.setIsLoading(false))
            // if(isSubscriptionActive){
            //     navigate('/dashboard')
            //     return
            // }

            // navigate('/pricing')
        })
    }

    const loginButtonConfig = {
        text: 'Login',
        onClick: ({ email, password }) => {
            
            if (authError) dispatch(authDispatch.clearAuthError())

            handleNormalLogin({ email, password })
        },
    }

    const orLoginWithGoogleButtonConfig = {
        text: 'or Login With Google',
        onClick: () => {
            if (authError) dispatch(authDispatch.clearAuthError())

            googleLogIn()
        },
    }

    return (
        <AuthenticationForm
            type='login'
            primaryButtonConfig={loginButtonConfig}
            secondaryButtonConfig={orLoginWithGoogleButtonConfig}
            errorOverrideMessage={authError && authError.message}
        />
    )
}
