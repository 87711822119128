// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .App {
  background-color: #282c34;
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}

.login-button {
  width: 200px;
  height: 60px;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: #ea4335;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  opacity: 0.8;
}

.login-button:hover {
  opacity: 1;
}

.booklist {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.image {
  width: 100px;
  height: 150px;
}

h3,
h1 {
  color: white;
} */`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GAwCG","sourcesContent":["/* .App {\n  background-color: #282c34;\n  display: flex;\n  justify-content: center;\n  height: 100vh;\n  align-items: center;\n}\n\n.login-button {\n  width: 200px;\n  height: 60px;\n  border: none;\n  border-radius: 5px;\n  color: white;\n  background-color: #ea4335;\n  font-size: 18px;\n  font-weight: bold;\n  cursor: pointer;\n  opacity: 0.8;\n}\n\n.login-button:hover {\n  opacity: 1;\n}\n\n.booklist {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-bottom: 10px;\n}\n\n.image {\n  width: 100px;\n  height: 150px;\n}\n\nh3,\nh1 {\n  color: white;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
