/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useCreateHttpClient, httpPost, httpGet } from '../../http/httpClient'
import { userDispatch } from '../../store'

export const useHandleVideoFunctions = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { httpClient } = useCreateHttpClient(
        process.env.REACT_APP_SERVICE_URL,
    )

    const assessVideoToBlogPost = async ({ videoUrl }) => {
        if(!videoUrl)
            return

         const res = await httpPost(httpClient, 'function/checkVideo', { videoUrl })
            .then((response) => 

                 response.data
                
                // dispatch(
                //     userDispatch.setCreateSubscriptionSecret(response.data),
                // )
                // navigate('/payForSubscription')

                
            )
            .catch((err) => console.log('convertVideoToBlogPost error: ', err))

        return res
    }

    const convertVideoToBlogPost = async ({ videoUrl, videoId }) => {
        if(!videoUrl)
            return

        await httpPost(httpClient, 'function/convertVideoToBlog', { videoUrl, videoId })
            .then((response) => {

                // dispatch(
                //     userDispatch.setCreateSubscriptionSecret(response.data),
                // )
                // navigate('/payForSubscription')

                
            })
            .catch((err) => console.log('convertVideoToBlogPost error: ', err))
    }

    const getVideoToBlogList = async () => {

        try {
            const response = await httpGet(httpClient, 'function/videoToBlogList')
           
            // dispatch(userDispatch.setCreateSubscriptionSecret(response.data));
            // navigate('/payForSubscription');
            return response.data;
        } catch (err) {
            console.log('getVideoToBlogList error:', err);
            // eslint-disable-next-line no-useless-return, consistent-return
            return
        }
    }

    const getVideoConversionDownload = async (videoId) => {

        try {
            const response = await httpGet(httpClient, `function/downloadConversionFile?conversionId=${videoId}`, {
                ResponseType: 'blob',
                Accept: 'text/plain',
            });
            // Handle the downloaded .txt file here
            return response.data
        } catch (err) {
            console.log('getVideoConversionDownload error:', err)
            // Handle the error here
        }
    }

    const getVideoConversion = async (videoId) => {

        try {
            const response = await httpGet(httpClient, `function/getConversion?conversionId=${videoId}`);
            // Handle the downloaded .txt file here
            return response.data
        } catch (err) {
            console.log('getVideoConversionGet error:', err)
            // Handle the error here
        }
    }

    return { assessVideoToBlogPost, convertVideoToBlogPost, getVideoToBlogList, getVideoConversionDownload, getVideoConversion }
}
