/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { PURGE } from "redux-persist"

const initialState = {
    isLoading: false
}

export const global = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        }
    },
})

global.extraReducers = (builder) => {
    builder.addCase(PURGE, () => initialState)
  }

// Action creators are generated for each case reducer function
export const { setIsLoading } = global.actions

export const globalReducer = global.reducer
