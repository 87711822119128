/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
// import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
// import { LocalSpinner } from '../atoms/spinners'
// import { } from '../assets/svg/SpinSvg.svg'
import { useSelector } from 'react-redux'
import { Button } from '../atoms/buttons'

const isValidEmail = (email) => {
    const regex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/

    return regex.test(email)
}

const isValidPassword = (password) => {
    const regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

    return regex.test(password)
}

function AuthenticationForm({
    type,
    primaryButtonConfig,
    secondaryButtonConfig,
    errorOverrideMessage,
}) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [isEmailValid, setIsEmailValid] = useState(false)
    const [isPasswordValid, setIsPasswordValid] = useState(false)
    const isLoading = useSelector((state) => state.global.isLoading)

    if (email && isValidEmail(email) !== isEmailValid)
        setIsEmailValid(isValidEmail(email))

    if (
        type === 'signup' &&
        password &&
        isValidPassword(password) !== isPasswordValid
    )
        setIsPasswordValid(isValidPassword(password))

    return (
        <div>
            <div>
                <label
                    htmlFor='email'
                    className='flex text-sm font-medium leading-6 text-gray-900'
                >
                    Email
                </label>
                <div className='relative mt-2 rounded-md shadow-sm'>
                    <input
                        type='email'
                        name='email'
                        id='email'
                        className='block w-full rounded-md border-0 pl-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                        placeholder='Enter your email address'
                        defaultValue='adamwathan'
                        aria-invalid='true'
                        aria-describedby='email-error'
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        disabled={isLoading}
                    />
                    <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
                        {!!email && !isEmailValid && (
                            <ExclamationCircleIcon
                                className='h-5 w-5 text-red-500'
                                aria-hidden='true'
                            />
                        )}
                    </div>
                </div>
                {!!email && !isEmailValid && (
                    <p className='mt-2 text-sm text-red-600' id='email-error'>
                        Not a valid email address.
                    </p>
                )}
            </div>

            <div>
                <label
                    htmlFor='password'
                    className='flex text-sm font-medium leading-6 text-gray-900'
                >
                    Password
                </label>
                <div className='mt-2'>
                    <input
                        type='password'
                        name='password'
                        id='password'
                        className='block w-full rounded-md border-0 pl-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                        placeholder='Enter your password'
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        disabled={isLoading}
                    />
                </div>
                {errorOverrideMessage && (
                    <p
                        className='mt-2 text-sm text-red-600'
                        id='password-error'
                    >
                        {errorOverrideMessage}
                    </p>
                )}
                {type === 'signup' &&
                    !errorOverrideMessage &&
                    !!password &&
                    !isPasswordValid && (
                        <p
                            className='mt-2 text-sm text-red-600'
                            id='password-error'
                        >
                            Password must be at least 8 characters long and
                            contain at least one uppercase letter, one lowercase
                            letter, one digit and one special character.
                        </p>
                    )}
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    paddingTop: '15px',
                }}
            >
                <div
                    style={{ justifyContent: 'space-between', display: 'flex' }}
                >
                   
                    <Button 
                        size='sm' 
                        onClick={() => primaryButtonConfig.onClick({ email, password })} 
                        isLoading={isLoading}
                        >
                            {primaryButtonConfig.text}
                    </Button>
                    <button
                        type='button'
                        onClick={secondaryButtonConfig.onClick}
                        className='login-button'
                        disabled={isLoading}
                    >
                        {secondaryButtonConfig.text}
                    </button>
                </div>
            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '50px',
                }}
            >
                {type === 'signup' && <p>Already have an account? Sign in</p>}
            </div>
        </div>
    )
}

export default AuthenticationForm
