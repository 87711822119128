import { useNavigate } from 'react-router-dom'
import {
    createUserWithEmailAndPassword,
    signOut,
    signInWithEmailAndPassword,
} from 'firebase/auth'
import { useDispatch } from 'react-redux'
import { auth, sendEmailVerification } from '../../firebase'
import {  authDispatch, userDispatch } from '../../store'
import { useCreateHttpClient, httpPost } from '../../http/httpClient'


export const useHandleAuth = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { httpClient } = useCreateHttpClient(
        process.env.REACT_APP_SERVICE_URL,
    )

    // const waitForAuthState = (maxRetries = 5, delayMs = 2000) => new Promise((resolve, reject) => {
    //     let retries = 0;
    
    //     // Ensure `auth` is initialized properly
    //     if (!auth) {
    //         reject(new Error('Auth object is not initialized.'));
    //         return;
    //     }
    
    //     // Check if auth.currentUser is already available
    //     if (auth.currentUser) {
    //         resolve(auth.currentUser);
    //         return;
    //     }
    
    //     const checkAuth = () => {
    //         if (auth.currentUser) {
    //             resolve(auth.currentUser);
    //         } else if (retries < maxRetries) {
    //             retries += 1;
    //             setTimeout(checkAuth, delayMs);
    //         } else {
    //             reject(new Error(`Auth state not ready after ${maxRetries} attempts, delay of ${delayMs}ms each`));
    //         }
    //     };
        
    //     checkAuth();
    // });

    const waitForAuthState = ( maxRetries = 5, baseDelayMs = 2000, maxDelayMs = 32000) => 
        new Promise((resolve, reject) => {
          let retries = 0;
      
          if (!auth) {
              reject(new Error('Auth object is not initialized.'));
              return;
          }
      
          if (auth.currentUser) {
              resolve(auth.currentUser);
              return;
          }
      
          const checkAuth = () => {
              if (auth.currentUser) {
                  resolve(auth.currentUser);
              } else if (retries < maxRetries) {
                  retries += 1;
                  // eslint-disable-next-line prefer-exponentiation-operator, no-restricted-properties
                  const delay = Math.min(baseDelayMs * Math.pow(2, retries - 1), maxDelayMs);
                  console.log(`Retry #${retries} in ${delay}ms`);  // Optional logging for debugging
                  setTimeout(checkAuth, delay);
              } else {
                  reject(new Error(`Auth state not ready after ${maxRetries} attempts, with exponential delays`));
              }
          };
      
          checkAuth();
      });
      

    

    const overWriteUser = ({ email, password, onEmailSent }) => {
        if (auth.currentUser.emailVerified === false)
            auth.currentUser.delete().then(() => {
                createUserWithEmailAndPassword(auth, email, password)
                    .then((result) => {
                        const { user } = result

                        // Send email verification
                        sendEmailVerification(user).then(() => {
                            onEmailSent()
                        }) // once sent it will use the verification page once the user clicks on the confirmation link
                    })
                    .catch((err) => console.log('error in hook: ', err))
            })
    }

    const refreshToken = async () => {
        // const token = await auth?.currentUser?.getIdToken(true)

        const currentUser = await waitForAuthState()
        const token = await currentUser?.getIdToken(true)

        if (token) {
            await localStorage.setItem('@token', token) // for some reason doing th
            await httpPost(httpClient, 'auth/login')
                .then((response) => {
                    dispatch(authDispatch.setLoggedIn(true))


                    const user = {
                        name: response.data.name,
                        subscription: {
                            priceTier: response.data.subscription?.priceTier,
                            isActive: response.data.subscription?.isActive,
                            subscriptionTokenBalance: response.data.subscription?.subscriptionTokenBalance,
                            topUpTokenBalance: response.data.subscription?.topUpTokenBalance,
                            topupTokenCost: response.data.subscription?.topupTokenCost
                        },
                    }

                    dispatch(userDispatch.setUser(user))

                    // 5 - put the token at localStorage (We'll use this to make requests)

                    // 6 - navigate user to the book list
                    // if (navTo) navigate(navTo)
                    if(user.subscription?.isActive) {
                        navigate('/dashboard')
                        return
                    }

                    navigate('/')
                    
                })
                .catch((err) => console.log('refresh error: ', err))
            // 5 - need to do something to stop the front end showing logged in when verified by firebase but waiting to hear back if verfied by back endd
           // await localStorage.setItem('@token', token)
        }
    }

    const standardSignup = ({ email, password, onEmailSent }) =>
        createUserWithEmailAndPassword(auth, email, password)
            .then((result) => {
                const { user } = result

                // Send email verification
                sendEmailVerification(user).then(() => {
                    onEmailSent()
                }) // once sent it will use the verification page once the user clicks on the confirmation link
            })
            .catch((err) => {
                console.log('error in hook: ', err)
                if (
                    err.message ===
                    'Firebase: Error (auth/email-already-in-use).'
                ) {
                    // if email already exists then
                    console.log('err.message: ', err.message)
                  
                    if(!auth.currentUser){ // looks like we can get an empty current user when email already exists
                        dispatch(
                            authDispatch.setAuthError({
                                type: 'firebase',
                                message: 'Email address is already taken.',
                            }),
                        )
                        return
                    }

                    if (auth.currentUser.emailVerified === false) {
                        // we check to see if the email has been verified and if it hasnt
                        overWriteUser({ email, password, onEmailSent }) // we can overwrite the user because its likely the same user who forgot to confirm their account via email previously
                        return
                    }

                    dispatch(
                        authDispatch.setAuthError({
                            type: 'firebase',
                            message: 'Email address is already taken.',
                        }),
                    )

                    
                }
            })

    const standardSignout = (onSuccess, onFail) =>
        signOut(auth)
            .then(async () => {
                localStorage.removeItem('@token')
                dispatch(authDispatch.setLoggedIn(false))
                if(onSuccess) onSuccess()
            })
            .catch((err) => { 
                console.log('error: ', err)
                if(onFail) onFail()
            
            })

    const standardLogin = ({ email, password }) => 
        signInWithEmailAndPassword(auth, email, password)
            .then(async () => {
                // 3 - pick the result and store the token
                // const token = await auth?.currentUser?.getIdToken(true);
               
                // refreshToken('/')
                await refreshToken()
            })
            .catch((err) => {
                if (err.message === 'Firebase: Error (auth/user-not-found).')
                    dispatch(
                        authDispatch.setAuthError({
                            type: 'firebase',
                            message: 'Wrong email or password.',
                        }),
                    )

                if (err.message === 'Firebase: Error (auth/wrong-password).')
                    dispatch(
                        authDispatch.setAuthError({
                            type: 'firebase',
                            message: 'Wrong email or password.',
                        }),
                    )
            })

    // const waitForAuthState = (maxRetries = 5, delayMs = 2000) => new Promise((resolve, reject) => {
    //         let retries = 0;
    //         const checkAuth = () => {
    //             if (auth.currentUser) {
    //                 resolve(auth.currentUser);
    //             } else if (retries < maxRetries) {
    //                 retries += 1
    //                 setTimeout(checkAuth, delayMs);
    //             } else {
    //                 reject(new Error("Auth state is not ready"));
    //             }
    //         }
    //         checkAuth();
    //     });

    

    const refreshTokenAfterStandardSignup = async ({ navTo, name }) => {
        const currentUser = await waitForAuthState()
        const token = await currentUser.getIdToken(true) // change back
        // const token = await auth?.currentUser?.getIdToken(true)
       
        if(!token) throw new Error("no token retrieved from fb auth")
        // const token = await auth.currentUser.getIdToken(true)

        if (token) {
            localStorage.setItem('@token', token)
            await httpPost(httpClient, 'auth/signup', { Name: name })
                .then((response) => {
                    //  todo - put something in place to ensure if response times out it doesnt set local storage or dipatched loggedn in is true

                    dispatch(authDispatch.setLoggedIn(true))

                    const user = {
                        name: response.data.name,
                        subscription: {
                            priceTier: response.data.subscription?.priceTier,
                            isActive: response.data.subscription?.isActive,
                            subscriptionTokenBalance: response.data.subscription?.subscriptionTokenBalance,
                            topUpTokenBalance: response.data.subscription?.topUpTokenBalance,
                            topupTokenCost: response.data.subscription?.topupTokenCost
                        },
                    }
                    dispatch(userDispatch.setUser(user))

                    // 5 - put the token at localStorage (We'll use this to make requests)

                    // 6 - navigate user to the book list
                    if (navTo) navigate(navTo)
                })
                .catch((err) => console.log('refresh error: ', err))
            // 5 - need to do something to stop the front end showing logged in when verified by firebase but waiting to hear back if verfied by back endd
        }
    }

    return {
        standardSignup,
        standardSignout,
        standardLogin,
        refreshToken,
        refreshTokenAfterStandardSignup,
    }
}
