import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
// import { signInWithPopup, signOut } from 'firebase/auth'
import { useSelector, useDispatch } from 'react-redux'
// import { auth, googleProvider } from '../firebase'
import { useHandleGoogleAuth } from '../apiCalls/auth/googleAuthMethods'
import { useHandleAuth } from '../apiCalls/auth/authMethods'

import AuthenticationForm from '../molecules/AuthenticationForm'
import { authDispatch } from '../store'

export default function Signup() {
    const dispatch = useDispatch()
    const { googleSignup } = useHandleGoogleAuth()
    const { standardSignup } = useHandleAuth()
    const [isVerificationEmailSent, setIsVerifcationEmailSent] = useState(false)

    const authError = useSelector((state) => state.auth.authError)
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)

    const navigate = useNavigate()

    useEffect(() => {
        if (isLoggedIn) navigate('/')

        return () => authError && dispatch(authDispatch.clearAuthError())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function handleNormalSignup({ email, password }) {
        standardSignup({
            email,
            password,
            onEmailSent: () => setIsVerifcationEmailSent(true),
        })
    }

    const signupButtonConfig = {
        text: 'Sign Up',
        onClick: ({ email, password }) => {
            if(authError) dispatch(authDispatch.clearAuthError())
            handleNormalSignup({ email, password })
        },
    }

    const orSignupWithGoogleButtonConfig = {
        text: 'or Signup With Google',
        onClick: () => {
            if(authError)  dispatch(authDispatch.clearAuthError())
            googleSignup()
        },
    }

    if (isVerificationEmailSent)
        return (
            <p>
                Sign up complete! Please check your email and click on the
                confirmation link.
            </p>
        )

    return (
        <AuthenticationForm
            type='signup'
            primaryButtonConfig={signupButtonConfig}
            secondaryButtonConfig={orSignupWithGoogleButtonConfig}
            errorOverrideMessage={authError && authError.message}
        />
    )
}
