import React, { useEffect } from "react";
import { useNavigate  } from "react-router-dom";
import { useSelector } from "react-redux";
import { CheckIcon } from '@heroicons/react/20/solid'
import { useHandleSubscriptions } from '../apiCalls/subscription/subscriptionMethods'
import { priceTiers } from "../constants";

const frequencies = [
    { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
    { value: 'annually', label: 'Annually', priceSuffix: '/year' },
]

function ProgressSubscription() { // if already chosen tier before signing up then show this page - but if not chosen a tier before signing up then
    // we never hsow this page and we go to the pricing page and fire createStripeCustomerAndSubscription when a tier is chosen

	const navigate = useNavigate();
	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
	const isSubscribed = useSelector((state) => state.user.subscription?.isActive)
	const selectedPriceTier = localStorage.getItem('@selectedPriceTier')
	const { createStripeCustomerAndSubscription } = useHandleSubscriptions()

    // scenarios
      // just signed up and redirected to this page
      // signed up but cleared cache in between signinig up and verifying email
      // already subscribed and signed up and somehow ended up on this page
      // not signed up 
      // not logged in

    // if(isLoggedIn && !isSubsribed && selectedPriceTier) // stay on this page
    //   return 'progressSubscription'


    // https://docs.stripe.com/billing/subscriptions/overview?locale=en-GB
    // read above sor subscription statuses

    

	useEffect(() => {
		if(isLoggedIn && !isSubscribed && !selectedPriceTier)
		navigate('/pricing')

		if(isLoggedIn && isSubscribed)
			navigate('/')

		if(!isLoggedIn){
			navigate('/login')
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const chosenTier = priceTiers.find((tier) => tier.priceCode === selectedPriceTier)

  return (
    <div className='bg-white py-24 sm:py-32'>
            <div className='mx-auto max-w-7xl px-6 lg:px-8'>
    <div className='isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3'>
    <div
                            key={chosenTier.id}
                            className={classNames(
                                chosenTier.mostPopular
                                    ? 'ring-2 ring-indigo-600'
                                    : 'ring-1 ring-gray-200',
                                'rounded-3xl p-8 xl:p-10',
                            )}
                        >
                            <div className='flex items-center justify-between gap-x-4'>
                                <h3
                                    id={chosenTier.id}
                                    className={classNames(
                                        chosenTier.mostPopular
                                            ? 'text-indigo-600'
                                            : 'text-gray-900',
                                        'text-lg font-semibold leading-8',
                                    )}
                                >
                                    {chosenTier.name}
                                </h3>
                                {chosenTier.mostPopular ? (
                                    <p className='rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600'>
                                        Most popular
                                    </p>
                                ) : null}
                            </div>
                            <p className='mt-4 text-sm leading-6 text-gray-600'>
                                {chosenTier.description}
                            </p>
                            <p className='mt-6 flex items-baseline gap-x-1'>
                                <span className='text-4xl font-bold tracking-tight text-gray-900'>
                                    {chosenTier.price[frequencies[0].value]}
                                </span>
                                <span className='text-sm font-semibold leading-6 text-gray-600'>
                                    {frequencies[0].priceSuffix}
                                </span>
                            </p>
                            {/* <button
                                type='button'
                                onClick={() => handleSelectTier(tier.priceCode)}
                                aria-describedby={tier.id}
                                className={classNames(
                                    tier.mostPopular
                                        ? 'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500'
                                        : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                                    'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
                                )}
                                style={{ width: '100%' }}
                            >
                                Buy plan
                            </button> */}
                            <ul className='mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10'>
                                {chosenTier.features.map((feature) => (
                                    <li key={feature} className='flex gap-x-3'>
                                        <CheckIcon
                                            className='h-6 w-5 flex-none text-indigo-600'
                                            aria-hidden='true'
                                        />
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                            </div>
    <div>
		Subscription details and button to go to payment intent
		<button type='button' onClick={createStripeCustomerAndSubscription}>
			Confirm tier
		</button>
        {/* <button type='button' onClick={navigate('/pricing')}>
			Choose different tier
		</button> */}
	</div>
    </div>
    </div>
    </div>
  )
}

export default ProgressSubscription



   