
import { useCreateHttpClient, httpPost} from '../../http/httpClient'


export const useOneTimePayment = () => {
    const { httpClient } = useCreateHttpClient(
        process.env.REACT_APP_SERVICE_URL,
    )

    const createPaymentSession = async ({ quantity, successRedirectionRoute = '/', cancellationRedirectionRoute = '/'}) => {
       

        await httpPost(httpClient, 'payment/createPaymentSession', { quantity, successRedirectionRoute, cancellationRedirectionRoute })
            .then((response) => {

                if (response.status === 200) {
                   
                    const sessionUrl = response.data.url;
                    window.location.href = sessionUrl; // Redirect to Stripe Checkout
                  } else {
                    console.error('Checkout session creation failed');
                  }
                
            })
            .catch((err) => console.log('refresh error: ', err))
    }

    return { createPaymentSession }
}
