/* eslint-disable react/prop-types */
import React from 'react';

function SimpleSelect({ label, options, defaultValue, placeholder, onSelect }) {
    return (
        <div>
        <label htmlFor={label} className="block text-sm font-medium leading-6 text-gray-900">
          {label}
        </label>
        <select
          id={label}
          name={label}
          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={(event) => onSelect(event.target.value)}
        >
            <option value="" disabled selected hidden>{placeholder}</option>
            {options.map((option) => <option key={option}>{option}</option>)}
          
        </select>
      </div>
    );
}

export default SimpleSelect;