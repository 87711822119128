import { setIsLoading } from './global';
import { setIsLoggedIn, setAuthError, clearAuthError } from './auth'

import { setUser, setCreateSubscriptionSecret } from './user';

export const globalDispatch = {

    setIsLoading
}

export const authDispatch = {
	setLoggedIn: setIsLoggedIn,
	setAuthError,
	clearAuthError,
}

export const userDispatch = {
	setUser,
	setCreateSubscriptionSecret,
}
