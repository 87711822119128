import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useCreateHttpClient, httpPost } from '../../http/httpClient'
import { userDispatch } from '../../store'
import  { useHandleAuth }  from '../auth/authMethods'

export const useHandleSubscriptions = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { httpClient } = useCreateHttpClient(
        process.env.REACT_APP_SERVICE_URL,
    )
    const { refreshToken } = useHandleAuth()

    const createStripeCustomerAndSubscription = async () => {
        const priceTier = localStorage.getItem('@selectedPriceTier')
        if (!priceTier) {
            console.log('no price tier selected in local storage')
            return
        }

        

        await httpPost(httpClient, 'payment/create', { priceTier })
            .then((response) => {

               
                dispatch(
                    userDispatch.setCreateSubscriptionSecret(response.data),
                )

                if(response.data.priceTierName === 'PayAsYouGo'){ // no need to pay if free subscription
                    refreshToken()
                    return
                }
                
                navigate('/payForSubscription')
            })
            .catch((err) => console.log('refresh error: ', err))
    }

    return { createStripeCustomerAndSubscription }
}
