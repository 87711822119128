import React from 'react'

function Terms() {
    return (
        <div className='bg-white py-24 sm:py-32'>
            <div className='mx-auto max-w-7xl px-6 lg:px-8'>
                <div className='mx-auto max-w-4xl text-center'>
                    <p className='mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl'>
                        Terms of Service
                    </p>
                    <p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
1. Acceptance of Terms`}
                    </p>
                    <p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
By accessing and using TubeToBlog ("the Site"), you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, do not use the Site.`}
                    </p>

                    <p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
2. Services Provided`}
                    </p>
                    <p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
TubeToBlog provides a service that converts YouTube videos into blog posts ("the Service"). The Service is intended for personal and commercial use.`}
                    </p>

                    <p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
3. User Responsibilities`}
                    </p>
                    <p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
You agree to use the Service in compliance with all applicable laws and regulations. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.`}
                    </p>

                    <p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        4. Intellectual Property
                    </p>
                    <p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
All content generated by the Service is your intellectual. Users retain ownership of the original YouTube videos and any content submitted to the Site, and take owenership any content created using TubeToBlog.`}
                    </p>

                    <p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        5. Payment Terms
                    </p>
                    <p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
Prices for the Service are clearly displayed on the Site in USD. Payment must be made in full before the Service is rendered. We accept various forms of payment, including [list accepted credit card brands].`}
                    </p>

                    <p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        6. Refund and Cancellation Policy
                    </p>
                    <p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
Refunds: Refunds are issued under specific conditions outlined on the Site. Contact [Contact Email] for refund requests.
Cancellations: You may cancel your order within [X] hours of purchase. After this period, cancellations are not accepted.`}
                    </p>

                    <p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        7. Delivery Policy
                    </p>
                    <p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
The converted blog posts will be delivered electronically via email or through your account on the Site within [X] hours/days of receiving the YouTube video link.`}
                    </p>

                    <p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        8. Privacy Policy
                    </p>
                    <p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
Your privacy is important to us. Please review our Privacy Policy for details on how we collect, use, and protect your personal information.
`}
                    </p>

                    <p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        9. Limitation of Liability
                    </p>
                    <p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
[Your Website Name] is not liable for any direct, indirect, incidental, or consequential damages arising from your use of the Service.`}
                    </p>

                    <p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        10. Modifications to Terms
                    </p>
                    <p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
We reserve the right to modify these Terms at any time. Changes will be posted on this page, and your continued use of the Service constitutes acceptance of the modified Terms.`}
                    </p>

                    <p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        11. Contact Information
                    </p>
                    <p className='mx-auto mt-6 max-w-2xl text-left text-lg leading-8 text-gray-600'>
                        {`
For any questions or concerns about these Terms, please contact us at:

Email: [Your Email]
Phone: [Your Phone Number]
Address: [Your Physical Address]`}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Terms
