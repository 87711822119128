import React from 'react'

// function GlobalSpinner() {

//     return (
//         <div className="flex gap-4 p-4 flex-wrap justify-center">

//             <img className="w-20 h-20 animate-spin" src="https://www.svgrepo.com/show/173880/loading-arrows.svg" alt="Loading icon"/>
//             {/* <img className="w-20 h-20 animate-spin" src="https://www.svgrepo.com/show/491270/loading-spinner.svg" alt="Loading icon"/>
//             <img className="w-20 h-20 animate-spin" src="https://www.svgrepo.com/show/474682/loading.svg" alt="Loading icon"/>
//             <img className="w-20 h-20 animate-spin" src="https://www.svgrepo.com/show/199956/loading-loader.svg" alt="Loading icon"/>
//             <img className="w-20 h-20 animate-spin" src="https://www.svgrepo.com/show/169757/loading-process.svg" alt="Loading icon"/>
//             <img className="w-20 h-20 animate-spin" src="https://www.svgrepo.com/show/70469/loading.svg" alt="Loading icon"/>
//             <img className="w-20 h-20 animate-spin" src="https://www.svgrepo.com/show/448500/loading.svg" alt="Loading icon"/> */}
//         </div>
//     )
// }

function GlobalSpinner() {
    return (
        <div className="flex gap-4 p-4 flex-wrap justify-center fixed inset-0 bg-gray-500 bg-opacity-50 z-50">
            <div className="flex items-center justify-center w-full h-full">
                {/* <img className="w-20 h-20 animate-spin" src="https://www.svgrepo.com/show/173880/loading-arrows.svg" alt="Loading icon"/> */}
                <img className="w-20 h-20 animate-spin" src="https://www.svgrepo.com/show/448500/loading.svg" alt="Loading icon"/>
            </div>
        </div>
    )
}

export default GlobalSpinner