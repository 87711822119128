/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Markdown from 'react-markdown'
import rehypeHighlight from 'rehype-highlight'
import rehypeRaw from 'rehype-raw'
import { useNavigate } from 'react-router-dom'
import { ClipboardDocumentIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { useHandleVideoFunctions } from '../../apiCalls/functionLayer/VideoApiHooks'
import TailwindSimpleTable from '../../molecules/tables/TailwindSimpleTable'
import { globalDispatch } from '../../store'
// import 'highlight.js/styles/github.css'; // Import a syntax highlighting theme
// import 'github-markdown-css/github-markdown.css'; // Import Markdown CSS styles
import { Button } from '../../atoms/buttons'

import 'bulma/css/bulma.min.css'
import 'highlight.js/styles/github.css'



export default function Dashboard() {
    const { getVideoToBlogList } = useHandleVideoFunctions()
    const [conversions, setConversions] = useState([])
    const dispatch = useDispatch()
    const [viewMode, setViewMode] = useState('processedVideos')
    const [convertedVideo, setConvertedVideo] = useState()
    const navigate = useNavigate()

    // const data = [
    //     { created: '2024-12-01', url: 'Lindsay Walton', status: 'Complete' },
    //     // More people...
    //   ]

    const { getVideoConversionDownload, getVideoConversion } = useHandleVideoFunctions()

    useEffect(() => {
        dispatch(globalDispatch.setIsLoading(true))
        getVideoToBlogList().then((data) => {
            setConversions(data || [])
            dispatch(globalDispatch.setIsLoading(false))
        })
    }, [])

    const handleDownload = async (videoId) => {
        const textFile = await getVideoConversionDownload(videoId)
        const element = document.createElement('a')
        const file = new Blob([textFile], { type: 'text/plain' })
        element.href = URL.createObjectURL(file)
        element.download = 'generated.txt'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
    }

    const handleView = async (videoId) => {
        // const textFile = await getVideoConversionDownload(videoId);
        // const element = document.createElement("a");
        // const file = new Blob([textFile], { type: "text/plain" });
        // element.href = URL.createObjectURL(file);
        // element.download = "generated.txt";
        // document.body.appendChild(element);
        // element.click();
        // document.body.removeChild(element);

        setViewMode('convertedVideo')
        const cv = await getVideoConversion(videoId)
        setConvertedVideo(cv)
    }

    const mappedConversions = conversions?.map((conversion) => ({
        created: conversion.createdAt,
        url: conversion.url,
        status: conversion.status,
        onClickDownload: () => handleDownload(conversion.id),
        onClickView: () => handleView(conversion.id),
    })).sort((a, b) => new Date(b.created) - new Date(a.created))

    return (
        <div className='px-4 py-5 sm:p-6'>
            {viewMode === 'processedVideos' && (
                <div>
                    <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Video to Blog Conversions</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        A list of all converted and currently being processed videos. 
                    </p>
                    <p className="mt-2 text-sm text-gray-700">
                    Please note if you are waiting for a video to be converted, it may take upto 30 minutes depeding on the size of the video.  We will notify you via email once the conversion is complete.
                    </p>
                    
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <button
                        type="button"
                        className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => navigate('/dashboard')}
                    >
                        Convert New Video
                    </button>
                </div>
            </div>
                    {/* <h3 className='text-base font-semibold leading-6 text-gray-900'>
                        Processed Videos
                    </h3> */}
                    <TailwindSimpleTable data={mappedConversions} />
                </div>
            )}
            {/*  */}
            {/* <div className="markdown-body"> */}

            {/* <SingleCentredCTAModal> */}

            {viewMode === 'convertedVideo' && (
                <div>
                    <div style={{ display: 'flex',width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '50px', gap: '10px'}}>
                    <a className="text-base font-semibold leading-6 text-gray-900" href={convertedVideo?.url} >{convertedVideo?.url}</a>
                    <div style={{ display: 'flex', gap: '10px'}}>
                        <Button
                            onClick={() => navigator.clipboard.writeText(convertedVideo?.output)}
                            type='button'
                            size='xs'
                            
                        >
                            <ClipboardDocumentIcon className="h-6 w-6 text-white" aria-hidden="true" /> 
                        </Button>
                        <Button
                            onClick={() => setViewMode('processedVideos')}
                            type='button'
                            size='lg'
                            
                        >
                            Download Markdown
                            <ArrowDownTrayIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            
                        </Button>
                        <Button
                            onClick={() => { setViewMode('processedVideos')
                            setConvertedVideo(null) 
                             }}
                            type='button'
                            size='lg'
                            buttonStyle='text'
                        >
                            Back to Processed Videos
                        </Button>
                    </div>
                    
                        
                    </div>
                    
                    <div className='content'>
                        <Markdown rehypePlugins={[rehypeRaw, rehypeHighlight]}>
                            {/* {markdown} */}
                            {convertedVideo?.output}
                        </Markdown>
                    </div>
                </div>
            )}
        </div>
    )
}
