// import { configureStore } from '@reduxjs/toolkit';
// import { globalReducer } from './global';
// import { userReducer } from './user';



// export const store = configureStore({
// 	reducer: {
// 		global: globalReducer,
// 		user: userReducer,
// 	},
// });

import { configureStore, combineReducers } from '@reduxjs/toolkit';

import storage from 'redux-persist/lib/storage';
import { 
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER, } from 'redux-persist';
// import thunk from 'redux-thunk';
import { userReducer } from './user';
import { globalReducer } from './global';
import { authReducer } from './auth'

const rootReducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    global: globalReducer

    // Add more reducers if needed
  });

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'user']
  }

const persistedReducer = persistReducer(persistConfig, rootReducer)


export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store)


// remember to use this:  https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
// import { PURGE } from "redux-persist";

// ...
// extraReducers: (builder) => {
//     builder.addCase(PURGE, (state) => {
//         customEntityAdapter.removeAll(state);
//     });
// }
