/* eslint-disable no-use-before-define */
import { useNavigate } from 'react-router-dom'
import { signInWithPopup, signOut } from 'firebase/auth'
import { useDispatch } from 'react-redux'
import { auth, googleProvider } from '../../firebase'
import { authDispatch, userDispatch } from '../../store'


import { useCreateHttpClient, httpPost } from '../../http/httpClient'

export const useHandleGoogleAuth = () => {
    const { httpClient } = useCreateHttpClient(
        process.env.REACT_APP_SERVICE_URL,
    )
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // const waitForAuthState = (maxRetries = 5, delayMs = 2000) => new Promise((resolve, reject) => {
    //     let retries = 0;
    
    //     // Ensure `auth` is initialized properly
    //     if (!auth) {
    //         reject(new Error('Auth object is not initialized.'));
    //         return;
    //     }
    
    //     // Check if auth.currentUser is already available
    //     if (auth.currentUser) {
    //         resolve(auth.currentUser);
    //         return;
    //     }
    
    //     const checkAuth = () => {
    //         if (auth.currentUser) {
    //             resolve(auth.currentUser);
    //         } else if (retries < maxRetries) {
    //             retries += 1;
    //             setTimeout(checkAuth, delayMs);
    //         } else {
    //             reject(new Error(`Auth state not ready after ${maxRetries} attempts, delay of ${delayMs}ms each`));
    //         }
    //     };
        
    //     checkAuth();
    // });

    const waitForAuthState = ( maxRetries = 5, baseDelayMs = 2000, maxDelayMs = 32000) => 
        new Promise((resolve, reject) => {
          let retries = 0;
      
          if (!auth) {
              reject(new Error('Auth object is not initialized.'));
              return;
          }
      
          if (auth.currentUser) {
              resolve(auth.currentUser);
              return;
          }
      
          const checkAuth = () => {
              if (auth.currentUser) {
                  resolve(auth.currentUser);
              } else if (retries < maxRetries) {
                  retries += 1;
                  // eslint-disable-next-line prefer-exponentiation-operator, no-restricted-properties
                  const delay = Math.min(baseDelayMs * Math.pow(2, retries - 1), maxDelayMs);
                  console.log(`Retry #${retries} in ${delay}ms`);  // Optional logging for debugging
                  setTimeout(checkAuth, delay);
              } else {
                  reject(new Error(`Auth state not ready after ${maxRetries} attempts, with exponential delays`));
              }
          };
      
          checkAuth();
      });

    const googleLogOut = () =>
        signOut(auth, googleProvider)
            .then(async () => {
                localStorage.removeItem('@token')
                dispatch(authDispatch.setLoggedIn(false))
            })
            .catch((err) => console.log('error: ', err))

    // const googleLogIn = () => signInWithPopup(auth, googleProvider).then(
    //   async (result) => {
    //     //3 - pick the result and store the token
    //     const token = await auth?.currentUser?.getIdToken(true);

    //     await fetch("https://localhost:7246/auth/login", {

    //     //use the authorization
    //       method: 'POST',
    //       headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': `Bearer ${token}`,
    //       },
    //     });

    //       dispatch(authDispatch.setLoggedIn(true))
    //       //4 - check if have token in the current user
    //       if (token) {
    //         //5 - put the token at localStorage (We'll use this to make requests)
    //         localStorage.setItem("@token", token);

    //         //6 - navigate user to the book list
    //         navigate("/book-list");
    //       }

    //     }).catch(err => console.log('error: ', err))

    const googleLogIn = () =>
        signInWithPopup(auth, googleProvider)
            .then(async () => {
                // 3 - pick the result and store the token
                

                refreshToken()

                // const token = await auth?.currentUser?.getIdToken(true)
                // if (token) {
                //     // 5 - put the token at localStorage (We'll use this to make requests)
                //     await localStorage.setItem('@token', token)
                // }
                // // await fetch("https://localhost:7246/auth/login", {

                // // //use the authorization
                // //   method: 'POST',
                // //   headers: {
                // //     'Content-Type': 'application/json',
                // //     'Authorization': `Bearer ${token}`,
                // //   },
                // // });

                // await httpPost(httpClient, 'auth/login').then((data) => {
                //     if(data.isFailedGoogleSignUp)
                //         navigate('/googleVerify')

                // }) // so q is - how do we not log in to api when we not signed up yet 

                
                
                // // but google lets us log in without signing up - we need to go to pricing if not chosen a tier, or go to payment if have chosen
                // // we need to go to '/googleVerify Instead i think

                // dispatch(authDispatch.setLoggedIn(true))
                // // 4 - check if have token in the current user

                // // 6 - navigate user to the book list
                // navigate('/')
            })
            .catch((err) => console.log('error: ', err))

    
        const refreshToken = async () => {
            // const token = await auth?.currentUser?.getIdToken(true)

            const currentUser = await waitForAuthState()
            const token = await currentUser.getIdToken(true) 
    
            if (token) {
                await localStorage.setItem('@token', token)
                await httpPost(httpClient, 'auth/login')
                    .then((response) => {
                       

                        if(response.data.isFailedGoogleSignUp){
                            navigate('/googleVerify') // this is when a user lgged in via google before signing up.  So we have nothing in db and need to signup on back end
                            return
                        }

                        dispatch(authDispatch.setLoggedIn(true))
                                
                        const user = {
                            name: response.data.name,
                            subscription: {
                                priceTier: response.data.subscription?.priceTier,
                                isActive: response.data.subscription?.isActive,
                                subscriptionTokenBalance: response.data.subscription?.subscriptionTokenBalance,
                                topUpTokenBalance: response.data.subscription?.topUpTokenBalance,
                                topupTokenCost: response.data.subscription?.topupTokenCost
                            },
                        }
    
                        dispatch(userDispatch.setUser(user))
    
                        // 5 - put the token at localStorage (We'll use this to make requests)
    
                        // 6 - navigate user to the book list
                        // if (navTo) navigate(navTo)
                        if(user.subscription?.isActive) {
                            navigate('/dashboard')
                            return
                        }
    
                        navigate('/')
                        
                    })
                    .catch((err) => console.log('refresh error: ', err))
                // 5 - need to do something to stop the front end showing logged in when verified by firebase but waiting to hear back if verfied by back endd
                // await localStorage.setItem('@token', token)
            }
        }

    // const googleSignup = () =>
    //     signInWithPopup(auth, googleProvider)
    //         .then(async () => {
    //             // 3 - pick the result and store the token
    //             const token = await auth?.currentUser?.getIdToken(true)

    //             console.log('token here?: ', token)

    //             if (token) {
    //                 // 5 - put the token at localStorage (We'll use this to make requests)
    //                 await localStorage.setItem('@token', token)
    //             }
    //             // await fetch("https://localhost:7246/auth/login", {

    //             // //use the authorization
    //             //   method: 'POST',
    //             //   headers: {
    //             //     'Content-Type': 'application/json',
    //             //     'Authorization': `Bearer ${token}`,
    //             //   },
    //             // });

    //             // httpPost(httpClient, 'auth/signup') // need to get name off token on back end - maybe make a new endpoint for this

    //             dispatch(authDispatch.setLoggedIn(true))
    //             // 4 - check if have token in the current user

    //             // 6 - navigate user to the book list
    //             navigate('/googleVerify')
    //         })
    //         .catch((err) => console.log('error: ', err))

    const googleSignup = () =>
        signInWithPopup(auth, googleProvider)
            .then(async () => {
                // 3 - pick the result and store the token
              

                // 6 - navigate user to the book list
                navigate('/googleVerify')
            })
            .catch((err) => console.log('error: ', err))


    
    const refreshTokenAfterGoogleSignup = async ({ navTo, name }) => {
        // const token = await auth?.currentUser?.getIdToken(true)

        const currentUser = await waitForAuthState()
        const token = await currentUser.getIdToken(true) 

        if (token) {
            await localStorage.setItem('@token', token)
            await httpPost(httpClient, 'auth/signup', { Name: name })
                .then((response) => {
                    //  todo - put something in place to ensure if response times out it doesnt set local storage or dipatched loggedn in is true

                    dispatch(authDispatch.setLoggedIn(true))

                    const user = {
                        name: response.data.name,
                        subscription: {
                            priceTier: response.data.subscription?.priceTier,
                            isActive: response.data.subscription?.isActive,
                            subscriptionTokenBalance: response.data.subscription?.subscriptionTokenBalance,
                            topUpTokenBalance: response.data.subscription?.topUpTokenBalance,
                            topupTokenCost: response.data.subscription?.topupTokenCost
                        },
                    }
                    dispatch(userDispatch.setUser(user))

                    // 5 - put the token at localStorage (We'll use this to make requests)

                    // 6 - navigate user to the book list
                    if (navTo) navigate(navTo)
                })
                .catch((err) => console.log('refresh error: ', err))
            // 5 - need to do something to stop the front end showing logged in when verified by firebase but waiting to hear back if verfied by back endd
        }
    }

    return { googleLogOut, googleLogIn, googleSignup, refreshTokenAfterGoogleSignup }
}
