import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHandleGoogleAuth } from '../apiCalls/auth/googleAuthMethods'
import { Button } from '../atoms/buttons'
import SimpleSelect from '../atoms/select/SimpleSelect'
import { useHandleSubscriptions } from '../apiCalls/subscription/subscriptionMethods'

export default function GoogleVerifyPage() {

    //   const [error, setError] = useState(false)
    const { refreshTokenAfterGoogleSignup } = useHandleGoogleAuth()
    const isLoading = useSelector((state) => state.global.isLoading)
    const [howHeardAboutUs, setHowHeardAboutUs] = useState()
    const [name, setName] = useState('')
    const { createStripeCustomerAndSubscription } = useHandleSubscriptions()

    const selectedPriceTier = localStorage.getItem('@selectedPriceTier')

    const handleVerify = () => { // TODO: after action code applied we should refresh token and then see if the user has selected a price tier,
   
        if(selectedPriceTier){
            refreshTokenAfterGoogleSignup({name}).then(() => 
                {createStripeCustomerAndSubscription() // this take us to payment page after
            })
        }

        if(!selectedPriceTier){
            refreshTokenAfterGoogleSignup({
                navTo: '/pricing',
                name,
            })
        }
  
    }

    return (
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '70px', paddingBottom: '70px'}}>
                <div className="w-96">    
                    <div className="w-96 flex flex-col gap-3" >
                        <h3 className='ml-px block  leading-6  text-lg font-medium leading-6 text-gray-900 text-center'>
                            We just need a couple more details to complete your account and you are set 🚀
                        </h3>
                        <div style={{ paddingTop: '20px'}}>
                            <label
                                htmlFor='name'
                                className='ml-px block  leading-6 text-sm font-medium leading-6 text-gray-900 '
                            >
                                Full name
                            </label>
                            <div className='mt-2'>
                            <input
                                type='text'
                                name='name'
                                id='name'
                                className='block w-full rounded-md border-0 pl-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                                placeholder='Ned Flanders'
                            />
                        </div>
                        </div>
                        
                        
                        <SimpleSelect onSelect={setHowHeardAboutUs} options={['Google', 'Reddit', 'Twitter', 'IndieHackers', 'Youtube', 'Other - Please Specify']} label="How did you hear about us?"  placeholder='Choose an option'/>
                        <Button size='md' onClick={handleVerify} isLoading={isLoading}  isDisabled={!name || !howHeardAboutUs} >
                            Verify Account
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
