const priceTiers = [
    {
        name: 'PayAsYouGo',
        id: 'tier-freelancer',
        href: '#',
        price: { monthly: '$0', annually: '$0' },
        description: 'Perfect for occasional users who need to turn video content into blog posts without a commitment.',
        features: [
            'No subscription fee',
            'Pay only for the content you convert',
            'Turn any video into a blog post as needed',
            '2 free conversion tokens per month',
            '$1 per conversion token',
        ],
        mostPopular: false,
        priceTier: 'PayAsYouGo',
    },
    {
        name: 'Standard',
        id: 'tier-startup',
        href: '#',
        price: { monthly: '$10', annually: '$100' },
        description: 'Ideal for growing creators or small businesses looking to scale their content production and drive more traffic.',
        features: [
            '10 free conversion tokens per month',
            'Additional conversions available at $1 per token',
            '$0.50 per conversion token'
            
        ],
        mostPopular: true,
        priceTier: 'Standard',
    },
    {
        name: 'Pro',
        id: 'tier-enterprise',
        href: '#',
        price: { monthly: '$20', annually: '$200' },
        description: 'For large creators who need a higher volume of conversions and advanced tools to enhance their video-to-blog process.',
        features: [
            '25 free conversions per month',
            'Additional conversions available at $0.25 per token',
            'Advanced analytics',
            'SEO keyword optimization',
            'Analytics'
        ],
        mostPopular: false,
        priceTier: 'Pro',
        isDisabled: true
    },
]

export default priceTiers