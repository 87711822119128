import axios from 'axios';
import { useDispatch } from 'react-redux';
// import LocalStorageService from './services/storage/localstorageservice'
// import router from './router/router'
import { useNavigate } from 'react-router-dom';
import { authDispatch } from '../store';

const useAddInterceptor = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const addRequestInterceptor = (axiosClient) => {
		axiosClient.interceptors.request.use(
			(config) => {
				//   const token = localStorageService.getAccessToken()
				const token = localStorage.getItem('@token');

				if (token) {

					// eslint-disable-next-line no-param-reassign
					config.headers.Authorization = `Bearer ${token}`;
				}

				// config.headers['Content-Type'] = 'application/json';
				return config;
			},
			(error) => {
				Promise.reject(error);
			},
		);
	};

	const addResponseInterceptor = (axiosClient) => {
		axiosClient.interceptors.response.use(
			(response) => response,
			(error) => {
			//   const originalRequest = error.config;

			//   console.log('error.response: ', error.response);
            console.log('error: ', error);

			  if (
					error?.response?.status === 401 || error?.response?.status === 403
				//  && originalRequest.url === 'http://127.0.0.1:3000/v1/auth/token'
			  ) {
					// router.push('/login')
					if (localStorage.getItem('@token')) { localStorage.removeItem('@token'); }

					dispatch(authDispatch.setLoggedIn(false));

					navigate('/login');
					return Promise.reject(error);
			  }

				//   if (
				// 	error.response.error.code === 400

				//   ) {
				// 	// router.push('/login')
				// 	console.log('holalaoalalla')
				// 	return Promise.reject(error)
				//   }

				//   if (error.response.status === 401 && !originalRequest._retry) {
				// 	originalRequest._retry = true
				// 	const refreshToken = localStorageService.getRefreshToken()
				// 	return axios
				// 	  .post('/auth/token', {
				// 		refresh_token: refreshToken
				// 	  })
				// 	  .then(res => {
				// 		if (res.status === 201) {
				// 		  localStorageService.setToken(res.data)
				// 		  axios.defaults.headers.common['Authorization'] =
				// 			'Bearer ' + localStorageService.getAccessToken()
				// 		  return axios(originalRequest)
				// 		}
				// 	  })
				//   }
			  return Promise.reject(error);
			},
		  );
	};

	return { addRequestInterceptor, addResponseInterceptor };
};

export const useCreateHttpClient = (baseURL, customHeaders) => {
	const { addRequestInterceptor, addResponseInterceptor } = useAddInterceptor();

	if (!baseURL) { throw new Error('Cannot create client if no base url'); }

	const client = axios.create({
		baseURL,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...customHeaders,
		},
	});

	addRequestInterceptor(client);
	addResponseInterceptor(client);

	return { httpClient: client };
};

export const httpGet = async (client, url, customConfig) => client.get(url, customConfig);

export const httpDelete = async (client, url, customConfig) => client.delete(url, customConfig);

export const httpPost = async (client, url, payload, customConfig) => client.post(url, payload, customConfig);

export const httpPut = async (client, url, payload, customConfig) => client.put(url, payload, customConfig);

export const httpPostFile = async (client, url, payload, customConfig) => {
	const formData = new FormData();
	formData.append('file', payload);
	return client.put(url, formData, customConfig);
};

export const httpPutFile = async (client, url, payload, config) => {
	const formData = new FormData();
	formData.append('file', payload);
	return client.put(url, formData, config);
};

export const httpPatch = async (client, url, payload, config) => client.patch(url, payload, config);
