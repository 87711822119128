import React from "react";
import LoginContainer from '../containers/LoginContainer'

export default function LoginPage() {
  return (
    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '70px', paddingBottom: '70px'}}>
        <div className="w-96">    
        <LoginContainer />
        </div>
      </div>
    </div>
  );
}



   