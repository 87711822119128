import { initializeApp } from 'firebase/app';
import {
	getAuth, GoogleAuthProvider, sendEmailVerification, applyActionCode,
} from 'firebase/auth';

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
	apiKey: 'AIzaSyC4we1aA-8EPHRhPySq8K7pem9gSeGjD5M',
	authDomain: 'blogaidb.firebaseapp.com',
	projectId: 'blogaidb',
	storageBucket: 'blogaidb.appspot.com',
	messagingSenderId: '314153795040',
	appId: '1:314153795040:web:47187ce31a51fa61efbf85',
	measurementId: 'G-K84FHNC1J6',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export { sendEmailVerification };
export { applyActionCode };
