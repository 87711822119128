import React, { useEffect } from 'react'

// import { useStripe } from '@stripe/react-stripe-js'
 // import { useNavigate } from 'react-router-dom'
 import  { useHandleAuth }  from '../apiCalls/auth/authMethods'

function PaymentSuccessful() {

    // const navigateTo = useNavigate()
    const { refreshToken } = useHandleAuth()

    const handlePaymentSuccess = () => {
        refreshToken()
    }

    useEffect(() => {
        // setTimeout(() => navigateTo('/dashboard'), 3000)
        setTimeout(() => handlePaymentSuccess(), 3000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <div>
            Payment successful, returning to homepage
        </div>
    )
}

export default PaymentSuccessful
