/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import { useEffect, useState } from 'react'
import { gapi } from 'gapi-script'

const CLIENT_ID =
    '742247727100-ki4vh2461psn0n6bjfvmndd2f0do4lfe.apps.googleusercontent.com' // Replace with your Google Client ID
// const API_KEY = process.env.REACT_APP_YOUTUBE_GAPI_KEY
const SCOPES = 'https://www.googleapis.com/auth/youtube.readonly'
const DISCOVERY_DOCS = [
    'https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest',
]

export const useYouTubeAuth = () => {
    const [isSignedIn, setIsSignedIn] = useState(false)
    const [videos, setVideos] = useState([])
    // eslint-disable-next-line no-unused-vars
    const [user, setUser] = useState()

    const initGapiClient = async () => {
        try {
            // Load the client:auth2 library
            await new Promise((resolve) => {
                gapi.load('client:auth2', resolve)
            })

            // Initialize the client
            await gapi.client.init({
                // apiKey: API_KEY,
                clientId: CLIENT_ID,
                discoveryDocs: DISCOVERY_DOCS,
                scope: SCOPES,
            })

            const authInstance = gapi.auth2.getAuthInstance()
            const isAuthSuccessful = authInstance.isSignedIn.get()
            setIsSignedIn(isAuthSuccessful)

            // Set a listener for changes in the sign-in state

            // If the user is already signed in, fetch their videos
            if (isAuthSuccessful) {
                const authResponse = authInstance.currentUser
                    .get()
                    .getAuthResponse()
                const email = authInstance.currentUser
                    .get()
                    .getBasicProfile()
                    .getEmail()
                setUser(email)
                // localStorage.setItem('yt_token_expiry', authResponse.expires_at);
                loadVideosOnIntialisation(email)
            }

            return { authInstance }
        } catch (error) {
            console.error('Error initializing GAPI client:', error)
            return null
        }
    }

    const loadVideosOnIntialisation = (userEmail) => {
        const date = new Date()
        date.setDate(date.getDate() + 3) // Adds 3 days to the current date
        const threeDaysFromNow = date.getTime()

        // if(!isSignedIn){
        //     console.log('!isSignedIn')
        //     youtubeLogin()
        //     return
        // }

        const lastRefreshKey = `yt_videos_last_refresh_${encodeURIComponent(
            userEmail,
        )}`
        if (
            localStorage.getItem(lastRefreshKey) &&
            parseInt(localStorage.getItem(lastRefreshKey), 10) <
                threeDaysFromNow
        ) {
            loadVideosFromStorage(userEmail)
            return
        }

        loadVideosFromYoutubeApi(userEmail)
    }

    const youtubeLogin = () => {
        const authInstance = gapi.auth2.getAuthInstance()
        authInstance
            .signIn({
                prompt: 'select_account', // Ensures the user can switch accounts
            })
            .then((googleUser) => {
                /// ///////////////

                const isAuthSuccessful = authInstance.isSignedIn.get()
                setIsSignedIn(isAuthSuccessful)

                // setIsSignedIn(isAuthSuccessful);

                // Set a listener for changes in the sign-in state

                // If the user is already signed in, fetch their videos
                if (isAuthSuccessful) {
                    const email = googleUser.getBasicProfile().getEmail()
                    setUser(email)

                    // const token = authResponse.access_token;
                    // localStorage.setItem('yt_access_token', token);
                    // localStorage.setItem('yt_token_expiry', authResponse.expires_at);

                    // loadVideosFromYoutubeApi(); // Assuming loadVideosFromYoutubeApi uses the token internally
                    loadVideosFromYoutubeApi(email)
                }
                /// ///////////////////

                // loadVideos()
            })
            .catch((error) => {
                console.error('youtube login error:', error)
            })
    }

    const handleLogin = () => {
        if (isSignedIn) return

        youtubeLogin()
    }

    const handleSwitchAccounts = async () => {
        const authInstance = gapi.auth2.getAuthInstance()

        if (authInstance) {
            try {
                // Sign out the current user
                await authInstance.signOut().then(() => {
                    console.log('Youtube User signed out.')
                })

                // Initialize the sign-in process again
                authInstance
                    .signIn({
                        prompt: 'select_account', // Ensures the user can switch accounts
                    })
                    .then((googleUser) => {
                        // localStorage.removeItem('yt_token_expiry')

                        const email = googleUser.getBasicProfile().getEmail()
                        setUser(email)

                        loadVideosOnIntialisation(email)

                        // localStorage.removeItem(`yt_videos_last_refresh_${encodeURIComponent(email)}`)
                        // localStorage.removeItem('yt_videos')

                       
                        // Handle the new signed-in user (e.g., update UI, store token, etc.)
                    })
                    .catch((error) => {
                        console.error(
                            'Error signing in with a new account: ',
                            error,
                        )
                    })
            } catch (error) {
                console.error('Error during account switch process: ', error)
            }
        } else {
            console.error(
                'Google Auth instance not found. Make sure gapi is initialized properly.',
            )
        }
    }

    const loadVideosFromYoutubeApi = (userEmail) => {
        const date = new Date()
        date.setDate(date.getDate() + 3) // Adds 3 days to the current date
        const threeDaysFromNow = date.getTime()

        const lastRefreshKey = `yt_videos_last_refresh_${encodeURIComponent(
            userEmail,
        )}`
        const videosKey = `yt_videos_${encodeURIComponent(userEmail)}`
        if (
            localStorage.getItem(lastRefreshKey) &&
            parseInt(localStorage.getItem(lastRefreshKey), 10) <
                threeDaysFromNow &&
            !localStorage.getItem(videosKey)
        ) {
            loadVideosFromStorage(userEmail)
            return
        }

        gapi.client.youtube.search
            .list({
                part: 'snippet',
                forMine: true,
                type: 'video',
                maxResults: 50,
            })
            .then((response) => {
                const videoItems = response.result.items
                setVideos(videoItems)
                // const lastRefreshKey = `yt_videos_last_refresh_${encodeURIComponent(userEmail)}`
                // const videosKey = `yt_videos_${encodeURIComponent(userEmail)}`
                localStorage.setItem(videosKey, JSON.stringify(videoItems))
                localStorage.setItem(lastRefreshKey, Date.now())
            })
            .catch((error) => console.error('Failed to fetch videos', error))
    }

    const loadVideosFromStorage = (userEmail) => {
        const videosKey = `yt_videos_${encodeURIComponent(userEmail)}`
        const vids = localStorage.getItem(videosKey)

        const parsedVids = JSON.parse(vids)
        setVideos(parsedVids)
    }

    const handleLogout = () => {
        const authInstance = gapi.auth2.getAuthInstance()
        authInstance.signOut()
        // localStorage.removeItem('yt_access_token')
        // localStorage.removeItem('yt_videos')
        // localStorage.removeItem('yt_token_expiry')
        setVideos([])
        setIsSignedIn(false)
        // setIsSignedIn(false)
    }

    useEffect(() => {
        initGapiClient().then((obj) => {
            // eslint-disable-next-line no-unused-vars
            const { authInstance } = obj
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        isSignedIn,
        videos,
        handleLogin,
        handleSwitchAccounts,
        handleLogout,
        user,
    }
}

export default useYouTubeAuth
