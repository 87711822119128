/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-redundant-roles */
import React from 'react'
import { BoltIcon } from '@heroicons/react/24/outline'

const navigation = {
    support: [
        { name: 'Pricing', href: '#' },
        { name: 'FAQ', href: '#' },
    ],

    legal: [
        { name: 'Cookie Policy', href: '#' },
        { name: 'Privacy', href: '/Privacy' },
        { name: 'Terms', href: '/Terms' },
    ],
}

export default function Example() {
    return (
        <footer
            aria-labelledby='footer-heading'
            className='relative border-t border-gray-900/10 py-24 sm:mt-56 sm:py-32'
        >
            <h2 id='footer-heading' className='sr-only'>
                Footer
            </h2>
            <div className='xl:grid xl:grid-cols-3 xl:gap-8'>
                <BoltIcon
                    className=' h-6 w-6 items-center stroke-indigo-600'
                    aria-hidden='true'
                />
                <div className='mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0'>
                    <div className='md:grid md:grid-cols-2 md:gap-8'>
                        <div className='mt-10 md:mt-0'>
                            <h3 className='text-sm font-semibold leading-6 text-gray-900'>
                                Support
                            </h3>
                            <ul role='list' className='mt-6 space-y-4'>
                                {navigation.support.map((item) => (
                                    <li key={item.name}>
                                        <a
                                            href={item.href}
                                            className='text-sm leading-6 text-gray-600 hover:text-gray-900'
                                        >
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className='md:grid md:grid-cols-2 md:gap-8'>
                        <div className='mt-10 md:mt-0'>
                            <h3 className='text-sm font-semibold leading-6 text-gray-900'>
                                Legal
                            </h3>
                            <ul role='list' className='mt-6 space-y-4'>
                                {navigation.legal.map((item) => (
                                    <li key={item.name}>
                                        <a
                                            href={item.href}
                                            className='text-sm leading-6 text-gray-600 hover:text-gray-900'
                                        >
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
