const priceTiers = [
    {
        name: 'PayAsYouGo',
        id: 'tier-freelancer',
        href: '#',
        price: { monthly: '$10', annually: '$144' },
        description: 'The essentials to provide your best work for clients.',
        features: [
            '5 products',
            'Up to 1,000 subscribers',
            'Basic analytics',
            '48-hour support response time',
        ],
        mostPopular: false,
        priceTier: 'PayAsYouGo',
    },
    {
        name: 'Standard',
        id: 'tier-startup',
        href: '#',
        price: { monthly: '$25', annually: '$288' },
        description: 'A plan that scales with your rapidly growing business.',
        features: [
            '25 products',
            'Up to 10,000 subscribers',
            'Advanced analytics',
            '24-hour support response time',
            'Marketing automations',
        ],
        mostPopular: true,
        priceTier: 'Standard',
    },
    {
        name: 'Pro',
        id: 'tier-enterprise',
        href: '#',
        price: { monthly: '$50', annually: '$576' },
        description: 'Dedicated support and infrastructure for your company.',
        features: [
            'Unlimited products',
            'Unlimited subscribers',
            'Advanced analytics',
            '1-hour, dedicated support response time',
            'Marketing automations',
            'Custom reporting tools',
        ],
        mostPopular: false,
        priceTier: 'Pro',
    },
]

export default priceTiers