/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
// import Markdown from 'react-markdown'
import { useDispatch, useSelector } from 'react-redux'
import { useHandleVideoFunctions } from '../../apiCalls/functionLayer/VideoApiHooks'
import { globalDispatch } from '../../store'
import { Button } from '../../atoms/buttons'
// import YouTubeLoginContainer from '../../containers/YouTubeLoginContainer'
import { useYouTubeAuth } from '../../apiCalls/functionLayer/YoutubeApiHooks'
import UploadedVideosTable from '../../molecules/tables/UploadedVideosTable'
import OneTimePaymentModal from '../../molecules/OneTimePaymentModal'

function AssessmentOutcomeText({ assessedVideo }) {
    if (assessedVideo && !assessedVideo.hasPassedAssessment)
        return (
            <div className='mt-2 max-w-xl text-sm text-gray-500'>
                <p>Video is invalid, please check the URL and try again</p>
            </div>
        )

    if (assessedVideo)
        return (
            <div className='mt-2 max-w-xl text-sm text-gray-500'>
                {(assessedVideo.userTokenBalance ||
                    assessedVideo.userTokenBalance === 0) && (
                    <p>
                        Your token balance is {assessedVideo.userTokenBalance}.
                    </p>
                )}
                {assessedVideo.tokenCost && (
                    <p>
                        This video will cost {assessedVideo.tokenCost} tokens to
                        convert.
                    </p>
                )}
                {assessedVideo.tokenCost > assessedVideo.userTokenBalance && (
                    <p>
                        You do not have enough tokens to cover this conversion.
                    </p>
                )}
            </div>
        )

    return (
        <div className='mt-2 max-w-xl text-sm text-gray-500'>
            <p>Video assessment takes around 20 seconds.</p>
        </div>
    )
}

export default function ConvertVideo() {
    if (process.env.REACT_APP_SITE_MODE === 'yt_account_holder')
        return <ConvertVideoYTAccountHolderMode />

    if (process.env.REACT_APP_SITE_MODE === 'none_yt_account_holder')
        return <ConvertVideoNoneYTAccountHolderMode />
}

function YoutubeAuthButtonRow({
    handleLogin,
    isLoading,
    handleLogout,
    isYoutubeSignedIn,
    handleSwitchAccounts,
    youTubeUserEmail,
}) {
    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',

                    gap: '10px',
                }}
            >
                {isYoutubeSignedIn ? (
                    <Button
                        size='md'
                        onClick={handleSwitchAccounts}
                        isLoading={isLoading} // remeber to set up loading for useYoutubehook
                        // isDisabled={!isYoutubeSignedIn}
                    >
                        Switch Accounts
                    </Button>
                ) : (
                    <Button
                        size='md'
                        onClick={handleLogin}
                        isLoading={isLoading} // remeber to set up loading for useYoutubehook
                        isDisabled={isYoutubeSignedIn}
                    >
                        Youtube Login
                    </Button>
                )}
                <Button
                    size='md'
                    onClick={handleLogout}
                    isLoading={isLoading} // remeber to set up loading for useYoutubehook
                    isDisabled={!isYoutubeSignedIn}
                >
                    Youtube Logout
                </Button>
                
               
            </div>
            {isYoutubeSignedIn  ? (
                    <div>{`${youTubeUserEmail} is logged in`}</div>
                ) : (
                    <div>You must log in to see your available videos</div>
                )}
        </div>
    )
}

function ConvertVideoYTAccountHolderMode() {
    const { convertVideoToBlogPost, assessVideoToBlogPost } =
        useHandleVideoFunctions()
    const [videoUrl, setVideoUrl] = useState('')
    const [selectedVideo, setSelectedVideo] = useState()
    const [isConversionTriggered, setIsConversionTriggered] = useState(false)
    const dispatch = useDispatch()
    const isLoading = useSelector((state) => state.global.isLoading)
    const [assessedVideo, setAssessedVideo] = useState()
    const [isBuyCreditsModalOpen, setIsBuyCreditsModalOpen] = useState(false)

    const {
        isSignedIn: isYoutubeSignedIn,
        videos,
        handleLogin,
        handleLogout,
        handleSwitchAccounts,
        user: youTubeUserEmail,
    } = useYouTubeAuth()

    const handleSelectVideo = (videoObject) => {
        setSelectedVideo(videoObject)
        setVideoUrl(videoObject.url)
        setAssessedVideo()
    }

    const formattedTableData = (videos || []).map(({ id, snippet }) => {
        const videoObject = {
            videoId: id.videoId,
            url: `https://www.youtube.com/watch?v=${id.videoId}`,
            title: snippet.title,
            created: snippet.publishedAt,
            thumbnailImages: {
                small: snippet.thumbnails.default.url,
                medium: snippet.thumbnails.medium.url,
            },
        }
        return {
            ...videoObject,
            onClickSelect: () => handleSelectVideo(videoObject),
        }
    })

    const handleConvertClick = () => {
        dispatch(globalDispatch.setIsLoading(true))

        if (!assessedVideo?.videoId) return

        convertVideoToBlogPost({
            videoUrl: assessedVideo.url,
            videoId: assessedVideo.videoId,
        }).then(() => {
            setVideoUrl('')
            setIsConversionTriggered(true)
            dispatch(globalDispatch.setIsLoading(false))
        })
    }

    const handleAssessClick = () => {
        dispatch(globalDispatch.setIsLoading(true))
        assessVideoToBlogPost({ videoUrl }).then((result) => {
            // setVideoUrl('')
            // setIsConversionTriggered(true)
            setAssessedVideo(result)
            dispatch(globalDispatch.setIsLoading(false))
        })
    }

    const handleClickBuyCredits = () => {
        setIsBuyCreditsModalOpen(true)
    }

    // const handleInput = (event) => {
    //     setAssessedVideo()
    //     setVideoUrl(event.target.value)
    // }

    const isConvertDisabled =
        !assessedVideo ||
        !assessedVideo.hasPassedAssessment ||
        assessedVideo.tokenCost > assessedVideo.userTokenBalance ||
        !isYoutubeSignedIn

    return (
        <div className='px-4 py-5 sm:p-6 flex flex-col gap-3'>
            {isBuyCreditsModalOpen && <OneTimePaymentModal successRedirectionRoute="/dashboard?success=true" tokenCost={assessedVideo.userTopUpTokenCost} priceTier={assessedVideo.userPriceTier} onBackdropClick={() => setIsBuyCreditsModalOpen(false)} />}
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                {/* <div className='px-4 py-5 sm:p-6 flex flex-col gap-3'> */}
                <div className=' flex flex-col gap-3'>
                    <h3 className='text-base font-semibold leading-6 text-gray-900'>
                        Convert Video To Blog Post
                    </h3>
                    {selectedVideo?.title && (
                        <div className='max-w-xl text-sm text-gray-500'>
                            <p>{selectedVideo.title}</p>
                        </div>
                    )}
                    <div className='max-w-xl text-sm text-gray-500'>
                        <p>
                            {selectedVideo?.url ||
                                'Select YouTube video you want to convert.'}
                        </p>
                    </div>
                    {/* <div className='w-full sm:max-w-xs'>
                        <label htmlFor='videoUrl' className='sr-only'>
                            Video Url
                        </label>
                       
                        {selectedVideo?.url}
                    </div> */}

                    <AssessmentOutcomeText assessedVideo={assessedVideo} />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '10px',
                        }}
                    >
                        <Button
                            size='sm'
                            onClick={handleAssessClick}
                            isLoading={isLoading}
                            isDisabled={
                                !selectedVideo ||
                                assessedVideo ||
                                !isYoutubeSignedIn
                            }
                        >
                            Assess
                        </Button>
                       
                        {/* {assessedVideo && assessedVideo.tokenCost > assessedVideo.userTokenBalance ? 
                        <OneTimePayment quantity={1} successRedirectionRoute="/dashboard?success=true" /> :
                        <Button
                            size='sm'
                            onClick={handleConvertClick}
                            isLoading={isLoading}
                            isDisabled={isConvertDisabled}
                        >
                            Convert
                        </Button>
                        } */}
                        {assessedVideo && assessedVideo.tokenCost > assessedVideo.userTokenBalance ? 
                         <Button
                         size='md'
                         onClick={handleClickBuyCredits}
                         isLoading={isLoading} // remeber to set up loading for useYoutubehook
                         isDisabled={isLoading}
                     >Buy Credits</Button> :
                        <Button
                            size='sm'
                            onClick={handleConvertClick}
                            isLoading={isLoading}
                            isDisabled={isConvertDisabled}
                        >
                            Convert
                        </Button>
                        }
                        
                        
                    </div>

                    {isConversionTriggered && (
                        <div className='mt-2 max-w-xl text-sm text-gray-500'>
                            <p>
                                The process of converting the video to blog post
                                has started. This process can take up to 30
                                minutes, you will receive and email notifcation
                                once complete, and the processed video will be
                                available for download in the Processed Video
                                Tab.
                            </p>
                        </div>
                    )}
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        gap: '10px',
                        width: '100%',
                    }}
                >
                    {selectedVideo?.thumbnailImages?.small ? (
                        <img
                            src={selectedVideo.thumbnailImages.small}
                            alt={selectedVideo.title}
                        />
                    ) : (
                        <div style={{ width: '120px', height: '90px' }} />
                    )}
                    <YoutubeAuthButtonRow
                        handleLogin={handleLogin}
                        isLoading={isLoading}
                        handleLogout={handleLogout}
                        isYoutubeSignedIn={isYoutubeSignedIn}
                        handleSwitchAccounts={handleSwitchAccounts}
                        youTubeUserEmail={youTubeUserEmail}
                    />
                </div>
            </div>

            {isYoutubeSignedIn && (
                <UploadedVideosTable data={formattedTableData} />
            )}
        </div>
    )
}

function ConvertVideoNoneYTAccountHolderMode() {
    const { convertVideoToBlogPost, assessVideoToBlogPost } =
        useHandleVideoFunctions()
    const [videoUrl, setVideoUrl] = useState('')
    const [isConversionTriggered, setIsConversionTriggered] = useState(false)
    const dispatch = useDispatch()
    const isLoading = useSelector((state) => state.global.isLoading)
    const [assessedVideo, setAssessedVideo] = useState()

    const handleConvertClick = () => {
        dispatch(globalDispatch.setIsLoading(true))

        if (!assessedVideo?.videoId) return

        convertVideoToBlogPost({
            videoUrl: assessedVideo.url,
            videoId: assessedVideo.videoId,
        }).then(() => {
            setVideoUrl('')
            setIsConversionTriggered(true)
            dispatch(globalDispatch.setIsLoading(false))
        })
    }

    const handleAssessClick = () => {
        dispatch(globalDispatch.setIsLoading(true))
        assessVideoToBlogPost({ videoUrl }).then((result) => {
            // setVideoUrl('')
            // setIsConversionTriggered(true)
            setAssessedVideo(result)
            dispatch(globalDispatch.setIsLoading(false))
        })
    }

    const handleInput = (event) => {
        setAssessedVideo()
        setVideoUrl(event.target.value)
    }

    const isConvertDisabled =
        !assessedVideo ||
        !assessedVideo.hasPassedAssessment ||
        assessedVideo.tokenCost > assessedVideo.userTokenBalance

    return (
        <div className='px-4 py-5 sm:p-6 flex flex-col gap-3'>
            <h3 className='text-base font-semibold leading-6 text-gray-900'>
                Convert Video To Blog Post
            </h3>
            <div className='mt-2 max-w-xl text-sm text-gray-500'>
                <p>Paste the url of the YouTube video you want to convert.</p>
            </div>
            <div className='w-full sm:max-w-xs'>
                <label htmlFor='videoUrl' className='sr-only'>
                    Video Url
                </label>
                <input
                    type='input'
                    name='videoUrl'
                    id='videoUrl'
                    className='block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                    placeholder='https://www.youtube.com/watch?v=A4_TFHzqAAg&t'
                    value={videoUrl}
                    onChange={handleInput}
                />
            </div>
            {/* <button
            type="button"
            className="mt-3 inline-flex items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:mt-0 w-20"
            onClick={handleConvertClick}
        >
            Convert
        </button> */}

            <AssessmentOutcomeText assessedVideo={assessedVideo} />
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <Button
                    size='sm'
                    onClick={handleAssessClick}
                    isLoading={isLoading}
                    isDisabled={assessedVideo}
                >
                    Assess
                </Button>
                <Button
                    size='sm'
                    onClick={handleConvertClick}
                    isLoading={isLoading}
                    isDisabled={isConvertDisabled}
                >
                    Convert
                </Button>
            </div>

            {isConversionTriggered && (
                <div className='mt-2 max-w-xl text-sm text-gray-500'>
                    <p>
                        The process of converting the video to blog post has
                        started. This process can take up to 30 minutes, you
                        will receive and email notifcation once complete, and
                        the processed video will be available for download in
                        the Processed Video Tab.
                    </p>
                </div>
            )}
        </div>
    )
}
