/* eslint-disable no-unused-vars */
// import React from 'react'
// import { useNavigate } from 'react-router-dom'

// function PricingPage() {
//     const navigate = useNavigate()
//     const handleSelectTier1 = () => {
//         localStorage.setItem(
//             '@selectedPriceTier',
//             'price_1O7QeNHNw57NDfv1FLWGIqDO',
//         )
//         navigate('/signup')
//     }

//     return (
//         <div className='mx-auto max-w-7xl sm:px-6 lg:px-8'>
//             {/* Content goes here */}
//             <div
//                 style={{
//                     display: 'flex',
//                     justifyContent: 'center',
//                     width: '100%',
//                     paddingTop: '70px',
//                     paddingBottom: '70px',
//                 }}
//             >
//                 <div className='w-96'>
//                     {/* <div onClick={handleSelectTier1}>
// 				<a href='/signup'>
// 					Sign Up to tier 1
// 				</a>
// 			</div> */}
//                     <button type='button' onClick={handleSelectTier1}>
//                         Sign Up to tier 1
//                     </button>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default PricingPage

import React, { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useHandleSubscriptions } from '../apiCalls/subscription/subscriptionMethods'
import PricingContainer from '../containers/PricingContainer'

// const frequencies = [
//     { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
//     { value: 'annually', label: 'Annually', priceSuffix: '/year' },
// ]
// const tiers = [
//     {
//         name: 'Basic',
//         id: 'tier-freelancer',
//         href: '#',
//         price: { monthly: '$10', annually: '$144' },
//         description: 'The essentials to provide your best work for clients.',
//         features: [
//             '5 products',
//             'Up to 1,000 subscribers',
//             'Basic analytics',
//             '48-hour support response time',
//         ],
//         mostPopular: false,
//         priceCode: 'price_1OzNVGHNw57NDfv1FfK38D8S',
//     },
//     {
//         name: 'Standard',
//         id: 'tier-startup',
//         href: '#',
//         price: { monthly: '$25', annually: '$288' },
//         description: 'A plan that scales with your rapidly growing business.',
//         features: [
//             '25 products',
//             'Up to 10,000 subscribers',
//             'Advanced analytics',
//             '24-hour support response time',
//             'Marketing automations',
//         ],
//         mostPopular: true,
//         priceCode: 'price_1OzNUqHNw57NDfv1R6AfZyQo',
//     },
//     {
//         name: 'Pro',
//         id: 'tier-enterprise',
//         href: '#',
//         price: { monthly: '$50', annually: '$576' },
//         description: 'Dedicated support and infrastructure for your company.',
//         features: [
//             'Unlimited products',
//             'Unlimited subscribers',
//             'Advanced analytics',
//             '1-hour, dedicated support response time',
//             'Marketing automations',
//             'Custom reporting tools',
//         ],
//         mostPopular: false,
//         priceCode: 'price_1OzNTfHNw57NDfv1w9eSFGQp',
//     },
// ]

// function classNames(...classes) {
//     return classes.filter(Boolean).join(' ')
// }

function PricingPage() {
    // const [frequency, setFrequency] = useState(frequencies[0])

    // const navigate = useNavigate()
    // const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
    // // const handleSelectTier = (priceCode) => {
    // //     localStorage.setItem('@selectedPriceTier', priceCode)
    // //     // if already logged in then we want to go to the thing that 
    // //     // navigate('/signup') 
    // //     navigate('/progressSubscription')
    // // }

    // const { createStripeCustomerAndSubscription } = useHandleSubscriptions()

    // const handleSelectTier = (priceCode) => {
    //     localStorage.setItem('@selectedPriceTier', priceCode)
        
    //     // if already logged in then we create customer and subscription and go straigt to payment, 

    //     if(isLoggedIn) // do we need to refresh toaken here to ensure we are logged in?
    //     createStripeCustomerAndSubscription()
        
    //     // if not logged in then we go to signup
    //     navigate('/signup') 
       
    // }

    return (
        <div className='bg-white py-24 sm:py-32'>
            <PricingContainer />
        </div>
    )
}

export default PricingPage
