/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import {
    Bars3Icon,
    BellIcon,
    XMarkIcon,
    BoltIcon,
} from '@heroicons/react/24/outline'
import { PlusIcon } from '@heroicons/react/20/solid'
import { useNavigate, useLocation } from 'react-router-dom'
// import { useHandleGoogleAuth } from './auth/googleAuthMethods'
import { useHandleAuth } from './apiCalls/auth/authMethods'
import './navStyles.css'
import { NavSkeletonLoader } from './atoms/skeletons'
import { globalDispatch } from './store'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
    const navigate = useNavigate()
    // const { googleLogOut } = useHandleGoogleAuth()
    const { standardSignout } = useHandleAuth()
    const dispatch = useDispatch()
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
    const usersName = useSelector((state) => state.user.name)

    const currentRoute = useLocation()
    const isLoading = useSelector((state) => state.global.isLoading)

    const handleSignOut = () => {
        dispatch(globalDispatch.setIsLoading(true))

        const handleSuccessfulSignout = () => {
            dispatch(globalDispatch.setIsLoading(false))
            navigate('/')
        }

        const handleUnsuccessfulSignout = () => {
            dispatch(globalDispatch.setIsLoading(false))
        }

        setTimeout(() => {
            standardSignout(handleSuccessfulSignout, handleUnsuccessfulSignout)
        }, 1000)
    }

    const decideNavUnderline = (navRoute) => {
        const currentPath = currentRoute.pathname
        if (currentPath === navRoute)
            return 'inline-flex items-center border-b-2 border-indigo-500 px-1 pt-1 text-xs font-bold tracking-tight text-gray-500 sm:text-lg'
            // return 'inline-flex items-center border-b-2 border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-900'
        

        // return 'inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700'
         return 'inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-xs font-bold tracking-tight text-gray-500 sm:text-lg'
    }

    return (
        <Disclosure as='nav' className='bg-white shadow'>
            {({ open }) => (
                <>
                    <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
                        {isLoading ? (
                            <NavSkeletonLoader />
                        ) : (
                            <div className='flex h-16 justify-between'>
                                <div className='flex'>
                                    <div className='-ml-2 mr-2 flex items-center md:hidden'>
                                        {/* Mobile menu button */}
                                        <Disclosure.Button className='relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
                                            <span className='absolute -inset-0.5' />
                                            <span className='sr-only'>
                                                Open main menu
                                            </span>
                                            {open ? (
                                                <XMarkIcon
                                                    className='block h-6 w-6'
                                                    aria-hidden='true'
                                                />
                                            ) : (
                                                <Bars3Icon
                                                    className='block h-6 w-6'
                                                    aria-hidden='true'
                                                />
                                            )}
                                        </Disclosure.Button>
                                    </div>
                                    <div className='flex flex-direction-row flex-shrink-0 items-center gap-1'>
                                        {/* <img
                                        className='h-8 w-auto'
                                        src='https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600'
                                        alt='Your Company'
                                    /> */}
                                        
                                        <BoltIcon
                                            className=' h-6 w-6 items-center stroke-indigo-600'
                                            aria-hidden='true'
                                        />
                                        <p className='text-xs font-bold tracking-tight text-gray-600 sm:text-lg'>
                                            TubeToBlog
                                        </p>
                                    </div>
                                    <div className='hidden md:ml-6 md:flex md:space-x-8'>
                                        {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                                        <a
                                            href='/'
                                            className={decideNavUnderline('/')}
                                        >
                                            Dashboard
                                        </a>
                                        <a
                                            href='/pricing'
                                            className={decideNavUnderline(
                                                '/pricing',
                                            )}
                                        >
                                            Pricing
                                        </a>

                                        <a
                                            href='/demo'
                                            className={decideNavUnderline(
                                                '/demo',
                                            )}
                                        >
                                            Demo
                                        </a>
                                    </div>
                                </div>
                                {/* {!isLoggedIn && */}
                                <div className='flex items-center'>
                                    {!isLoggedIn && (
                                        <div className='flex-shrink-0'>
                                            <button
                                                type='button'
                                                className='relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                                                onClick={() =>
                                                    navigate('/signup')
                                                }
                                            >
                                                <PlusIcon
                                                    className='-ml-0.5 h-5 w-5'
                                                    aria-hidden='true'
                                                />
                                                Sign Up
                                            </button>
                                        </div>
                                    )}
                                    {!isLoggedIn && (
                                        <div className='flex-shrink-0 md:ml-4'>
                                            <button
                                                type='button'
                                                className='relative inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                                                onClick={() =>
                                                    navigate('/login')
                                                }
                                            >
                                                <PlusIcon
                                                    className='-ml-0.5 h-5 w-5'
                                                    aria-hidden='true'
                                                />
                                                Log In
                                            </button>
                                        </div>
                                    )}
                                    {isLoggedIn && (
                                        <div className='hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center'>
                                            <button
                                                type='button'
                                                className='relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                            >
                                                <span className='absolute -inset-1.5' />
                                                <span className='sr-only'>
                                                    View notifications
                                                </span>
                                                <BellIcon
                                                    className='h-6 w-6'
                                                    aria-hidden='true'
                                                />
                                            </button>

                                            {/* Profile dropdown */}
                                            <Menu
                                                as='div'
                                                className='relative ml-3'
                                            >
                                                <div>
                                                    <Menu.Button className='relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
                                                        <span className='absolute -inset-1.5' />
                                                        <span className='sr-only'>
                                                            Open user menu
                                                        </span>
                                                        {/* <img
                                                        className='h-8 w-8 rounded-full'
                                                        src='https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
                                                        alt=''
                                                    /> */}
                                                        <div className='navcircle'>
                                                            <p className='navcircleinitial'>
                                                                {usersName?.slice(
                                                                    0,
                                                                    1,
                                                                )}
                                                            </p>
                                                        </div>
                                                    </Menu.Button>
                                                </div>
                                                <Transition
                                                    as={Fragment}
                                                    enter='transition ease-out duration-200'
                                                    enterFrom='transform opacity-0 scale-95'
                                                    enterTo='transform opacity-100 scale-100'
                                                    leave='transition ease-in duration-75'
                                                    leaveFrom='transform opacity-100 scale-100'
                                                    leaveTo='transform opacity-0 scale-95'
                                                >
                                                    <Menu.Items className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <a
                                                                    href='/profile'
                                                                    className={classNames(
                                                                        active
                                                                            ? 'bg-gray-100'
                                                                            : '',
                                                                        'block px-4 py-2 text-sm text-gray-700',
                                                                    )}
                                                                >
                                                                    Your Profile
                                                                </a>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <a
                                                                    href='/settings'
                                                                    className={classNames(
                                                                        active
                                                                            ? 'bg-gray-100'
                                                                            : '',
                                                                        'block px-4 py-2 text-sm text-gray-700',
                                                                    )}
                                                                >
                                                                    Settings
                                                                </a>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <button
                                                                    type='button'
                                                                    style={{
                                                                        width: '100%',
                                                                        textAlign:
                                                                            'left',
                                                                    }}
                                                                    className={classNames(
                                                                        active
                                                                            ? 'bg-gray-100'
                                                                            : '',
                                                                        'block px-4 py-2 text-sm text-gray-700',
                                                                    )}
                                                                    onClick={
                                                                        handleSignOut
                                                                    }
                                                                >
                                                                    Sign out
                                                                </button>
                                                            )}
                                                        </Menu.Item>
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>
                                    )}
                                </div>
                                {/* } */}
                            </div>
                        )}
                    </div>

                    <Disclosure.Panel className='md:hidden'>
                        <div className='space-y-1 pb-3 pt-2'>
                            {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                            <Disclosure.Button
                                as='a'
                                href='#'
                                // className='block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700 sm:pl-5 sm:pr-6'
                                className='block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-xs font-bold tracking-tight text-gray-600 sm:text-lg'
                                
                            >
                                Dashboard
                            </Disclosure.Button>
                            <Disclosure.Button
                                as='a'
                                href='/pricing'
                                className='block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 sm:pl-5 sm:pr-6'
                            >
                                Pricing
                            </Disclosure.Button>
                            <Disclosure.Button
                                as='a'
                                href='#'
                                className='block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 sm:pl-5 sm:pr-6'
                            >
                                Projects
                            </Disclosure.Button>
                            <Disclosure.Button
                                as='a'
                                href='#'
                                className='block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 sm:pl-5 sm:pr-6'
                            >
                                Calendar
                            </Disclosure.Button>
                        </div>
                        <div className='border-t border-gray-200 pb-3 pt-4'>
                            <div className='flex items-center px-4 sm:px-6'>
                                <div className='flex-shrink-0'>
                                    {/* <img
                                        className='h-10 w-10 rounded-full'
                                        src='https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
                                        alt=''
                                    /> */}
                                    <div className='navcircle'>
                                        <p className='navcircleinitial'>
                                            {usersName?.slice(0, 1)}
                                        </p>
                                    </div>
                                </div>
                                <div className='ml-3'>
                                    <div className='text-base font-medium text-gray-800'>
                                        Tom Cook
                                    </div>
                                    <div className='text-sm font-medium text-gray-500'>
                                        tom@example.com
                                    </div>
                                </div>
                                <button
                                    type='button'
                                    className='relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                >
                                    <span className='absolute -inset-1.5' />
                                    <span className='sr-only'>
                                        View notifications
                                    </span>
                                    <BellIcon
                                        className='h-6 w-6'
                                        aria-hidden='true'
                                    />
                                </button>
                            </div>
                            <div className='mt-3 space-y-1'>
                                <Disclosure.Button
                                    as='a'
                                    href='#'
                                    className='block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6'
                                >
                                    Your Profile
                                </Disclosure.Button>
                                <Disclosure.Button
                                    as='a'
                                    href='#'
                                    className='block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6'
                                >
                                    Settings
                                </Disclosure.Button>
                                <Disclosure.Button
                                    as='a'
                                    href='#'
                                    className='block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6'
                                    onClick={handleSignOut} // this isnt working
                                >
                                    Sign out
                                </Disclosure.Button>
                            </div>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}
