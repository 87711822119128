/* eslint-disable no-unused-vars */
// import React from 'react'
// import { useNavigate } from 'react-router-dom'

// function PricingPage() {
//     const navigate = useNavigate()
//     const handleSelectTier1 = () => {
//         localStorage.setItem(
//             '@selectedPriceTier',
//             'price_1O7QeNHNw57NDfv1FLWGIqDO',
//         )
//         navigate('/signup')
//     }

//     return (
//         <div className='mx-auto max-w-7xl sm:px-6 lg:px-8'>
//             {/* Content goes here */}
//             <div
//                 style={{
//                     display: 'flex',
//                     justifyContent: 'center',
//                     width: '100%',
//                     paddingTop: '70px',
//                     paddingBottom: '70px',
//                 }}
//             >
//                 <div className='w-96'>
//                     {/* <div onClick={handleSelectTier1}>
// 				<a href='/signup'>
// 					Sign Up to tier 1
// 				</a>
// 			</div> */}
//                     <button type='button' onClick={handleSelectTier1}>
//                         Sign Up to tier 1
//                     </button>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default PricingPage

import React, { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useHandleSubscriptions } from '../apiCalls/subscription/subscriptionMethods'
import { priceTiers } from '../constants'

const frequencies = [
    { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
    { value: 'annually', label: 'Annually', priceSuffix: '/year' },
]


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function PricingContainer() {
    const [frequency, setFrequency] = useState(frequencies[0])

    const navigate = useNavigate()
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
    // const handleSelectTier = (priceTier) => {
    //     localStorage.setItem('@selectedPriceTier', priceTier)
    //     // if already logged in then we want to go to the thing that 
    //     // navigate('/signup') 
    //     navigate('/progressSubscription')
    // }

    const { createStripeCustomerAndSubscription } = useHandleSubscriptions()

    const handleSelectTier = (priceTier) => {
        localStorage.setItem('@selectedPriceTier', priceTier)
        
        // if already logged in then we create customer and subscription and go straigt to payment, 

        if(isLoggedIn) // do we need to refresh toaken here to ensure we are logged in? yes cos could be an old token
        createStripeCustomerAndSubscription()
        
        // if not logged in then we go to signup
        navigate('/signup') 
       
    }

    const getClassName = (isMostPopular, isDisabled) => {

        if(isDisabled)
            return 'text-gray-400 ring-1 ring-inset ring-gray-300'

        if(isMostPopular)
            return 'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500'

        return 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300'
    }

    return (

            <div className='mx-auto max-w-7xl px-6 lg:px-8'>
                <div className='mx-auto max-w-4xl text-center'>
                    <h2 className='text-base font-semibold leading-7 text-indigo-600'>
                        Pricing
                    </h2>
                    <p className='mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
                        Pricing plans for teams of&nbsp;all&nbsp;sizes
                    </p>
                </div>
                <p className='mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600'>
                    Choose a plan that fits your needs and start transforming your video content today. Whether you&apos;re a solo creator, a small team, or a large enterprise, we have a plan that&apos;s perfect for you.
                </p>
                {/* <div className='mt-16 flex justify-center'>
                    <RadioGroup
                        value={frequency}
                        onChange={setFrequency}
                        className='grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200'
                    >
                        <RadioGroup.Label className='sr-only'>
                            Payment frequency
                        </RadioGroup.Label>
                        {frequencies.map((option) => (
                            <RadioGroup.Option
                                key={option.value}
                                value={option}
                                className={({ checked }) =>
                                    classNames(
                                        checked
                                            ? 'bg-indigo-600 text-white'
                                            : 'text-gray-500',
                                        'cursor-pointer rounded-full px-2.5 py-1',
                                    )
                                }
                            >
                                <span>{option.label}</span>
                            </RadioGroup.Option>
                        ))}
                    </RadioGroup>
                </div> */}
                <div className='isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3'>
                    {priceTiers.map((tier) => (
                        <div
                            key={tier.id}
                            className={classNames(
                                tier.mostPopular
                                    ? 'ring-2 ring-indigo-600'
                                    : 'ring-1 ring-gray-200',
                                'rounded-3xl p-8 xl:p-10',
                            )}
                        >
                            <div className='flex items-center justify-between gap-x-4'>
                                <h3
                                    id={tier.id}
                                    className={classNames(
                                        tier.mostPopular
                                            ? 'text-indigo-600'
                                            : 'text-gray-900',
                                        'text-lg font-semibold leading-8',
                                    )}
                                >
                                    {tier.name}
                                </h3>
                                {tier.mostPopular ? (
                                    <p className='rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600'>
                                        Most popular
                                    </p>
                                ) : null}
                            </div>
                            <p className='mt-4 text-sm leading-6 text-gray-600'>
                                {tier.description}
                            </p>
                            <p className='mt-6 flex items-baseline gap-x-1'>
                                <span className='text-4xl font-bold tracking-tight text-gray-900'>
                                    {tier.price[frequency.value]}
                                </span>
                                <span className='text-sm font-semibold leading-6 text-gray-600'>
                                    {frequency.priceSuffix}
                                </span>
                            </p>
                            <button
                                type='button'
                                onClick={() => handleSelectTier(tier.priceTier)}
                                aria-describedby={tier.id}
                                // className={classNames(
                                //     tier.mostPopular
                                //         ? 'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500'
                                //         : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                                //     'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
                                // )}
                                className={classNames(getClassName(tier.mostPopular, tier.isDisabled), 'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600')}
                                style={{ width: '100%' }}
                                disabled={tier.isDisabled}
                            >
                                {tier.isDisabled ? 'Coming Soon' : 'Buy plan'}
                                {/* <a
                href={tier.href}
                aria-describedby={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? 'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500'
                    : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                  'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                )}
              >
                Buy plan
              </a> */}
                            </button>

                            <ul className='mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10'>
                                {tier.features.map((feature) => (
                                    <li key={feature} className='flex gap-x-3'>
                                        <CheckIcon
                                            className='h-6 w-5 flex-none text-indigo-600'
                                            aria-hidden='true'
                                        />
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>

    )
}

export default PricingContainer
